import { render, staticRenderFns } from "./Snapshots.vue?vue&type=template&id=61f16633&scoped=true&"
import script from "./Snapshots.vue?vue&type=script&lang=js&"
export * from "./Snapshots.vue?vue&type=script&lang=js&"
import style0 from "./Snapshots.vue?vue&type=style&index=0&id=61f16633&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f16633",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QLayout,QHeader,QToolbar,QInput,QIcon,QPopupProxy,QDate,QBtn,QSelect,QItem,QItemSection,QBtnToggle,QFooter,QPagination,QPageContainer,QScrollArea,QCard,QCardSection});qInstall(component, 'directives', {ClosePopup});
