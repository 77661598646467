module.exports = {
    VERSION: 'v1.0.0-rc.17',
    get GRID_LAYOUT_STANDARD() {
      return  [
          {
            "x":0,
            "y":0,
            "w":3,
            "minW":3,
            "h":2,
            "minH":2,
            "i":"0",
            "static":false,
            "content":"DiskLimitWidget"
          },
        {
          "x":3,
          "y":0,
          "w":3,
          "minW":3,
          "h":2,
          "minH":2,
          "i":"1",
          "static":false,
          "content":"ObjectsLimitWidget"
        },
        {
          "x":6,
          "y":0,
          "w":3,
          "minW":3,
          "h":2,
          "minH":2,
          "i":"2",
          "static":false,
          "content":"DevicesLimitWidget"
        },
        {
          "x":9,
          "y":0,
          "w":3,
          "minW":3,
          "h":2,
          "minH":2,
          "i":"3",
          "static":false,
          "content":"CamerasLimitWidget"
        },
        {
          "x":0,
          "y":2,
          "w":4,
          "minW":4,
          "h":5,
          "minH":5,
          "i":"4",
          "static":false,
          "content":"StorageWidget"
        },
        {
          "x":4,
          "y":2,
          "w":4,
          "minW":4,
          "h":5,
          "minH":5,
          "i":"5",
          "static":false,
          "content":"SnapshotsWidget"
        },
        {
          "x":8,
          "y":2,
          "w":4,
          "minW":4,
          "h":5,
          "minH":5,
          "i":"6",
          "static":false,
          "content":"OfflineWidget"
        },
        {
          "x":0,
          "y":7,
          "w":8,
          "minW":8,
          "h":5,
          "minH":5,
          "i":"7",
          "static":false,
          "content":"TasksWidgetAll"
        }
      ]
    },
}
