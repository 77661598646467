<template>
  <div style="height: 100%">
    <slot name="widget" :update="update" :data="$data" :props="$props" :apexChartData="donutDiskData" :apexChartOptions="donutDiskOptions"></slot>
  </div>
</template>

<script>
import _ from "lodash";
import {widget} from "./mixins/widget";
import {numberWithSpaces} from "../utils";

let memory = {
  // limit: 0,
  // fullMemory: 0,
  total: 0,
  snapshotsMemory: 0,
  videosMemory: 0
}

export default {
  name: "StorageWidget",
  mixins: [widget],
  data() {
    return {
      name: 'Диск',
      // icon: 'fas fa-hdd',
      total: 0,
      // limit: 0,
      result: [],
      totalSnapshots: 0,
      totalVideos: 0
    }
  },

  async mounted() {

  },

  methods: {
    // checkoutVisibility() {
    //   if(!this.updatedProps) return
    //   this.updatedProps = false
    //   setTimeout(() => this.updatedProps = true, 300)
    // },

    async fetchFilesize() {
      const result = await this.server.files.readMany({

        groupBy: ['type'],

        aggregate: {
          sum: 'filesize'
        }
      })

      this.result = result.data
      console.debug(this.result)
    },

    snapshots() {
      this.totalSnapshots = (_.sumBy(this.result.filter(x => x.type === 'image/jpg'), x => x.sum.filesize / 1000))/1000/1000
    },

    videos() {
      this.totalVideos = (_.sumBy(this.result.filter(x => x.type === 'video/mp4'), x => x.sum.filesize / 1000))/1000/1000
    },

    async fetch() {
      await  this.fetchFilesize()
      // await this.fetchLimit()
      this.snapshots()
      this.videos()
      this.total = this.totalSnapshots + this.totalVideos
      memory = {
        // limit: this.limit,
        // fullMemory: this.total,
        total: this.totalSnapshots + this.totalVideos,
        snapshotsMemory: this.totalSnapshots,
        videosMemory: this.totalVideos
      }
    },
  },

  computed: {
    // radialBarMultipleOptions() {
    //   return {
    //     chart: {
    //       type: 'radialBar',
    //     },
    //     labels: ['Диск', 'Снимки', 'Видео'],
    //     theme: {
    //       palette: 'palette7',
    //     },
    //     plotOptions: {
    //       radialBar: {
    //         labels: {
    //           show: false
    //         },
    //         dataLabels: {
    //           name: {
    //             show: true,
    //           },
    //           value: {
    //             show: true,
    //             offsetY: 3,
    //             formatter: function (val, w) {
    //                 if(val === w.config.series[0].toString()) {
    //                   if(!memory.limit) {
    //                     return `${memory.fullMemory.toFixed(1)} GB / Ꝏ`
    //                   }
    //                   else {
    //                     return ((Number(val) * memory.limit) / 100).toFixed(1) +' GB' + ' / ' + memory.limit + ' GB'
    //                   }
    //                 }
    //                 return ((Number(val) * memory.fullMemory) / 100).toFixed(1) +' GB'
    //               }
    //             }
    //           },
    //         }
    //       },
    //     legend: {
    //       show: true,
    //       floating: false,
    //       fontSize: '16px',
    //       position: 'bottom',
    //       offsetX: 0,
    //       offsetY: 0,
    //       labels: {
    //         useSeriesColors: false,
    //       },
    //       markers: {
    //         size: 0
    //       },
    //       formatter: function(seriesName) {
    //         let value = 0
    //
    //         if(seriesName === 'Диск') value = memory.fullMemory
    //         else if(seriesName === 'Снимки') value = memory.snapshotsMemory
    //         else if(seriesName === 'Видео') value = memory.videosMemory
    //
    //         return `${seriesName} ${value.toFixed(1) + ' GB'}`
    //       },
    //       itemMargin: {
    //         vertical: 3
    //       }
    //     },
    //   }
    // },

    dataSeries() {
      return _.sortBy([
        { label: 'Снимки', value: (this.totalSnapshots*100)/this.total },
        { label: 'Видео', value: (this.totalVideos*100)/this.total },
      ], x => x.value).reverse()
    },

    donutDiskData() {
      return this.dataSeries.map(x => x.value)
    },

    donutDiskOptions() {
      return {
        chart: {
          type: 'donut',
        },
        labels: this.dataSeries.map(x => x.label),
        // theme: {
        //   palette: this.chartsPallet,
        // },
        colors: this.chartsColors,
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                },
                value: {
                  show: true,
                  formatter: function(value) {
                    // console.debug(value)
                    // return Number(value).toFixed(1) + ' %'

                    // return (value * memory.total / 100).toFixed(1) + ' GB'
                    return Math.round((value * memory.total / 100) * 10) / 10 + ' GB'
                  },
                },
                total: {
                  show: true,
                  showAlways: false,
                  label: 'Всего',
                  formatter: function (w) {
                    // console.debug(w.globals.seriesTotals.length)
                    // console.debug(memory.total)
                    // w.globals.seriesTotals.forEach(item => {
                    //   console.debug(item * memory.total / 100)
                    // })


                    // return w.globals.seriesTotals.reduce((a, b) => {
                    //   return ((a * memory.total / 100) + (b * memory.total / 100)).toFixed(1) + ' GB'
                    // })
                    // return memory.total.toFixed(1) + ' GB'
                    return Math.round(memory.total * 10) / 10 + ' GB'
                  }
                }
              }
            }
          }
        },
        tooltip: {
          y: {
            formatter: function(value) {
              console.debug(value)
              // return value.toFixed(1) + ' %'
              return Math.round(value * 10) / 10 + ' %'
            },
            title: {
              formatter: (seriesName) => seriesName + ':'
            }
          },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true,
          floating: false,
          fontSize: '16px',
          position: 'right',
          offsetX: 0,
          offsetY: 0,
          labels: {
            useSeriesColors: false,
          },
          markers: {
            size: 0
          },
          formatter: function(seriesName) {
            let value = 0

            if(seriesName === 'Снимки') value = Math.round(memory.snapshotsMemory * 10) / 10
            else if(seriesName === 'Видео') value = Math.round(memory.videosMemory * 10) / 10

            return `${seriesName} ${value + ' GB'}`
          },
          itemMargin: {
            vertical: 3
          }
        },
      }
    }
  }
}
</script>

<style scoped>

</style>