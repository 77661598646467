<template>
  <div style="height: 100%">
    <slot name="widget" :update="update" :data="$data" :props="$props" :apexChartData="donutPeriodData" :apexChartOptions="donutPeriodOptions"></slot>
  </div>
</template>

<script>
import {widget} from "./mixins/widget";
import {numberWithSpaces} from "../utils";
import _ from "lodash";

let counts = {
  day: 0,
  week: 0,
  month: 0,
  total: 0
}

export default {
  name: "SnapshotsWidget",
  mixins: [widget],

  data() {
    return {
      // icon: 'far fa-images',
      name: 'Снимки',
      total: 0,
      day: 0,
      week: 0,
      month: 0
    }
  },

  async mounted() {

  },

  methods: {
    // checkoutVisibility() {
    //   if(!this.updatedProps) return
    //   this.updatedProps = false
    //   setTimeout(() => this.updatedProps = true, 300)
    // },

    async fetch() {
      await this.fetchTotal()
      await this.fetchDay()
      await this.fetchWeek()
      await this.fetchMonth()

      counts = {
        day: this.day,
        week: this.week,
        month: this.month,
        total: this.totalCountPeriod
      }
    },

    async fetchTotal() {
      let result = null

      try {
        result = await this.server.items('snapshot').readMany({
          limit: 0,
          meta: 'total_count'
        })
      }
      catch (e) {
        console.debug('fetching error', e)
        this.$q.notify({
          message: `Ошибка получения кол-ва снимков: ${e.message}`
        })
      }
      if(result) this.total = result.meta.total_count
    },

    async fetchDay() {
      let result = null
      const today = new Date( Date.now() - 24 * 60 * 60 * 1000 )

      try {
        result = await this.server.items('snapshot').readMany({
          limit: 0,
          meta: 'filter_count',
          filter: {
            date_created: {
              _gte: today.toISOString()
            }
          }
        })
      }
      catch (e) {
        console.debug('fetching error', e)
      }

      if(result) this.day = result.meta.filter_count
    },

    async fetchWeek() {
      let result = null

      const today = new Date( Date.now() - 24 * 7 * 60 * 60 * 1000 )

      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      today.setMilliseconds(0)

      try {
        result = await this.server.items('snapshot').readMany({
          limit: 0,
          meta: 'filter_count',
          filter: {
            date_created: {
              _gte: today.toISOString()
            }
          }
        })
      }
      catch (e) {
        console.debug('fetching error', e)
      }

      if(result) this.week = result.meta.filter_count
    },

    async fetchMonth() {
      let result = null

      const today = new Date( Date.now() - 24 * 30 * 60 * 60 * 1000 )

      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      today.setMilliseconds(0)

      try {
        result = await this.server.items('snapshot').readMany({
          limit: 0,
          meta: 'filter_count',
          filter: {
            date_created: {
              _gte: today.toISOString()
            }
          }
        })
      }
      catch (e) {
        console.debug('fetching error', e)
      }

      if(result) this.month = result.meta.filter_count
    },
  },

  computed: {
    dataSeries() {
      return _.sortBy([
        { label: 'За сутки', value: (this.day*100)/this.totalCountPeriod },
        { label: 'За неделю', value: (this.week*100)/this.totalCountPeriod },
        { label: 'За месяц', value: (this.month*100)/this.totalCountPeriod },
      ], x => x.value).reverse()
    },

    donutPeriodData() {
      return this.dataSeries.map(x => x.value)
    },

    donutPeriodOptions() {
      return {
        chart: {
          type: 'donut',
        },
        labels: this.dataSeries.map(x => x.label),
        // theme: {
        //   palette: this.chartsPallet,
        // },
        colors: this.chartsColors,
        // plotOptions: {
        //   radialBar: {
        //     labels: {
        //       show: false
        //     },
        //     dataLabels: {
        //
        //       name: {
        //         show: true,
        //       },
        //       value: {
        //         show: true,
        //         offsetY: 3,
        //       },
        //
        //     }
        //   }
        // },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                },
                value: {
                  show: true,
                  formatter: function(value) {
                    console.debug(value)
                    // return Number(value).toFixed(1) + ' %'
                    return numberWithSpaces(Math.round(value * counts.total / 100))
                  },
                },
                total: {
                  show: true,
                  showAlways: false,
                  label: 'Всего',
                  formatter: function (w) {
                    // console.debug(w.globals.seriesTotals.length)
                    console.debug(counts.total)
                    // w.globals.seriesTotals.forEach(item => {
                    //   console.debug(item * memory.total / 100)
                    // })
                    // return w.globals.seriesTotals.reduce((a, b) => {
                    //   return (a * countsPeriod.total / 100) + (b * countsPeriod.total / 100)
                    // })
                    return numberWithSpaces(counts.month)
                  }
                }
              }
            }
          }
        },
        tooltip: {
          y: {
            formatter: function(value) {
              console.debug(value)
              // return value.toFixed(1) + ' %'
              return Math.round(value * 10) / 10 + ' %'
            },
            title: {
              formatter: (seriesName) => seriesName + ':'
            }
          },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true,
          floating: false,
          fontSize: '16px',
          position: 'right',
          offsetX: 0,
          offsetY: 0,
          labels: {
            useSeriesColors: false,
          },
          markers: {
            size: 0
          },

          formatter: function(seriesName) {
            let value = 0

            if(seriesName === 'За месяц') value = numberWithSpaces(counts.month)
            else if(seriesName === 'За сутки') value = numberWithSpaces(counts.day)
            else if(seriesName === 'За неделю') value = numberWithSpaces(counts.week)

            // if(seriesName === 'За месяц') value = counts.month
            // else if(seriesName === 'За сутки') value = counts.day
            // else if(seriesName === 'За неделю') value = counts.week

            return `${seriesName} ${value}`
          },

          itemMargin: {
            vertical: 3
          }
        },
      }
    },
    totalCountPeriod() {
      return this.day + this.week + this.month
    },
  }
}
</script>

<style scoped>

</style>