<template>
  <div style="display: flex; flex-direction: column; align-content: stretch; width: 45%;">
  <q-card class="col" style="display: flex; flex-direction: column; justify-content: space-between" :style="onlineColor">
    <q-card-section class="column" style="padding: 25px 16px 16px 16px">
        <q-badge v-if="unit.device" color="grey absolute" style="top: 0; left: 2px">#{{unit.device.ident}}</q-badge>
        <div class="text-primary" style="font-size: 16px; margin-bottom: 10px">{{unit.name}}</div>
          <div v-if="positionValid" class="text-caption" style="margin-bottom: 10px">
            {{ getAddress }}
          </div>
          <div v-else style="margin-bottom: 10px">
            Позиция не определена
          </div>
        <table v-if="position" class="col self-center" style="border-collapse: collapse; border: 1px solid lightgray; margin-bottom: 10px">
          <tr>
            <td  style="border: 1px solid lightgray; padding: 5px">
              <div class="row items-center">
                <q-icon name="speed" class="text-grey" style="margin-right: 5px"></q-icon>
                <div>{{speed}} км/ч</div>
              </div>
            </td>
            <td style="border: 1px solid lightgray; padding: 5px">
              <div class="row items-center">
                <svg width="14px" height="14px" style="margin-right: 5px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="satellite" class="svg-inline--fa fa-satellite fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="#9e9e9e" d="M502.60969,310.04206l-96.70393,96.71625a31.88151,31.88151,0,0,1-45.00765,0L280.572,326.34115l-9.89231,9.90759a190.56343,190.56343,0,0,1-5.40716,168.52287c-4.50077,8.50115-16.39342,9.59505-23.20707,2.79725L134.54715,400.05428l-17.7999,17.79929c.70324,2.60972,1.60965,5.00067,1.60965,7.79793a32.00544,32.00544,0,1,1-32.00544-32.00434c2.79735,0,5.18838.90637,7.7982,1.60959l17.7999-17.79929L4.43129,269.94287c-6.798-6.81342-5.70409-18.6119,2.79735-23.20627a190.58161,190.58161,0,0,1,168.52864-5.407l9.79854-9.79821-80.31053-80.41716a32.002,32.002,0,0,1,0-45.09987L201.96474,9.29814A31.62639,31.62639,0,0,1,224.46868,0a31.99951,31.99951,0,0,1,22.59759,9.29814l80.32615,80.30777,47.805-47.89713a33.6075,33.6075,0,0,1,47.50808,0l47.50807,47.50645a33.63308,33.63308,0,0,1,0,47.50644l-47.805,47.89713L502.71908,265.036A31.78938,31.78938,0,0,1,502.60969,310.04206ZM219.56159,197.433l73.82505-73.82252-68.918-68.9-73.80942,73.80689Zm237.74352,90.106-68.90233-68.9156-73.825,73.82252,68.918,68.9Z">
                  </path>
                </svg>
                <div>{{position.satellites}}</div>
              </div>
            </td>
            <td style="border: 1px solid lightgray; padding: 5px">
              <div class="row items-center">
                <i class="fas fa-road text-grey" style="margin-right: 5px"></i>
                <div>{{position.mileage | numberAround | numberWithSpaces}} км</div>
              </div>
            </td>
            <td style="border: 1px solid lightgray; padding: 5px">
              <div class="row items-center">
                <q-icon class="text-grey" name="height" style="margin-right: 5px"></q-icon>
                <div>{{position.altitude}} м</div>
              </div>
            </td>
          </tr>
        </table>

        <div class="row">
          <div v-if="!unit.cameras || unit.cameras.length === 0" class="text-caption">
            нет камер
          </div>

          <div v-for="camera in unit.cameras" :key="camera.id">

            <q-avatar v-if="camera.last_snapshot" rounded style="cursor: pointer; margin-right: 10px">
              <img :src="snapshotSrc(camera.last_snapshot)" @click="openSnapshotDialog(camera.last_snapshot)"/>
                <q-tooltip>
                  {{camera.name}}
                </q-tooltip>
<!--              <q-dialog v-model="openSnapshot">-->
<!--                <q-card style="width: 50% !important; max-width: 50% !important">-->
<!--                  <img :src="getSrc(camera.last_snapshot.image)"/>-->
<!--                  <q-btn fab icon="close" style="position: absolute; right: 30px; bottom: 30px;  z-index: 1500" color="primary" @click="openSnapshot = false"/>-->
<!--                </q-card>-->
<!--              </q-dialog>-->

            </q-avatar>

            <q-avatar v-else rounded style="cursor: pointer; margin-right: 5px">
              <img src="../assets/no-image.png"/>
              <q-tooltip>
                {{camera.name}}
              </q-tooltip>
            </q-avatar>

          </div>
          <snapshot-dialog :image="currentImage" :openSnapshot="openSnapshot" v-on:close-snapshot-dialog="openSnapshot = false"/>
        </div>
    </q-card-section>

    <q-card-section class="row items-center bg-grey-1 justify-between" style="padding: 10px; border-top: 1px solid lightgray">
      <div class="text-caption">
        {{timestamp}}
      </div>

      <div>
        <q-btn flat no-caps label="Карта" color="primary" :disabled="position ? false : true" @click="openMap=true"/>
        <q-btn flat no-caps label="Открыть" color="primary" @click="openUnit=true; openUnitWindow({id: unit.id, openUnit: openUnit})"/>
<!--        <q-btn flat no-caps label="Открыть" color="primary" @click="openUnit = true"/>-->



<!--          <router-link :to="linkToItem(unit.id)" target="_blank" class="custom-router-link">Открыть</router-link>-->
<!--          <router-link :to="linkToItem(unit.id)" class="custom-router-link">Открыть</router-link>-->
      </div>

    </q-card-section>
  </q-card>

<!--    <unit-dialog :id="unit.id" :openUnit="openUnit" v-on:close-unit-dialog="openUnit = false"/>-->





<!--    <q-dialog-->
<!--        autofocus-->
<!--        v-model="openUnit"-->
<!--        persistent-->
<!--        :maximized="maximizedToggle"-->
<!--        transition-show="slide-up"-->
<!--        transition-hide="slide-down"-->
<!--        @keydown.esc="openUnit = false"-->
<!--    >-->
<!--      <q-card>-->
<!--      <q-bar class="bg-primary">-->
<!--        <div style="color: white">{{unit.name}}</div>-->
<!--        <q-space />-->
<!--        <q-btn flat icon="close" color="white" v-close-popup/>-->
<!--      </q-bar>-->

<!--      <unit :id="unit.id" />-->

<!--      </q-card>-->
<!--    </q-dialog>-->




  <q-dialog v-model="openMap">
    <div style="position: relative; max-width: none; width: 50%">
        <my-map :unit="unit"/>
    </div>
  </q-dialog>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment"
import {numberWithSpaces, numberAround, positionToAddress, snapshotSrc} from '../utils'
import MyMap from "../views/MyMap";
import Unit from "../views/Unit";
import SnapshotDialog from "./SnapshotDialog";
import UnitDialog from "./UnitDialog";

export default {
  name: "UnitPreview",
  components: { MyMap, Unit, SnapshotDialog, UnitDialog },
  props: {
    unit: {
      required: true,
      type: Object
    },

    time: {
      default: new Date()
    }
  },

  data() {
    return {
      openMap: false,
      openUnit: false,
      // maximizedToggle: true,
      address: null,
      openSnapshot: false,
      currentImage: {
        image: {
          id: '',
          storage: ''
        }
      }
    }
  },

  async mounted() {
    // console.debug(this.unit)
    // const dialogInner = document.querySelector(".q-dialog__inner")
    // dialogInner.classList.remove("no-pointer-events")

    const cachedPosition = this.getCachedPosition(this.unit)

    if(!this.positionValid) {
      this.setPosition({unit: this.unit, position: null})
      this.setAddress({unit: this.unit, value: null})
    }
    else if(!cachedPosition || cachedPosition.latitude !== this.position.latitude || cachedPosition.longitude !== this.position.longitude) {
      this.setPosition({unit: this.unit, position: this.position})
      await this.convertPosition()
    }

    this.address = this.getCachedAddress(this.unit)
  },

  filters: {
    numberWithSpaces,
    numberAround,
  },

  computed: {
    ...mapGetters({
      'server': "server/instance",
      'getCachedAddress': 'units/getAddress',
      'getCachedPosition': 'units/getPosition'
    }),

    position() {
      return this.unit.position?.[0]
    },

    onlineColor() {
      const time = this.time
      const item = this.position
      if(!item) return 'border-left: 2px solid grey'

      const seconds = (time - moment(item.date_updated).toDate()) / 1000

      if(seconds <= 300) return 'border-left: 2px solid green'
      else if(seconds > 300 && seconds < 1200) return 'border-left: 2px solid yellow'
      else return 'border-left: 2px solid red'
    },

    positionValid() {
      return this.position?.latitude && this.position?.longitude
    },

    timestamp() {
      const now = this.time
      if(!this.position?.date_updated) return 'неизвестно'
      const time = new Date(this.position.date_updated)
      const diff = time - now
      return moment.duration(diff, 'millisecond').humanize(true)
    },

    speedColor() {
      return this.positionValid ? 'primary' : null
    },

    speed() {
      return this.position?.speed ?? '??'
    },

    getAddress() {
      return this.address ?? `${this.position.latitude} ${this.position.longitude}`
    }
  },

  methods: {
    ...mapActions({
      'setAddress': 'units/setAddress',
      'setPosition': 'units/setPosition'
    }),

    snapshotSrc,

    openSnapshotDialog(value) {
      this.currentImage = value
      this.openSnapshot = true
    },

    linkToItem(id) {
      return `/units/${id}`
    },

    async convertPosition() {
      if (this.position.latitude && this.position.longitude) {
        const result = await positionToAddress(this.position.latitude, this.position.longitude)
        const addr = result.address
        // this.address = `${addr.city || ''} ${addr.road}`
        // this.address = result.display_name
        this.setAddress({unit: this.unit, value: result.display_name})
      }

    },

    openUnitWindow(value) {
      // this.openUnit = true
      this.$emit('openUnitWindow', value)
    }

    // openUnit() {
    //   const id = this.linkToItem(this.unit.id)
    //   let routeData = this.$router.resolve(id);
    //   window.open(routeData.href, '_blank');
    // },

  },

  watch: {
    position() {
      this.convertPosition()
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/styles/quasar.variables.sass"

.custom-router-link
  text-decoration: none
  color: $primary

.custom-router-link:visited
  color: $primary

//.custom-q-dialog
//  pointer-events: auto!important

.custom-q-page-sticky
  box-shadow: none
</style>