<template>
  <q-page style="background-color: whitesmoke;">
    <q-layout view="hhh LpR ffr" container style="min-height: calc(100vh - 50px);">

      <q-header class="border-for-header custom-for-index" style="background-color: #ffffff;">
        <q-toolbar class="row items-center justify-between">
          <q-select dense style="width: 300px" v-model="defaultTypeSort" :options="typesSort" label="Сортировать"/>
          <q-input
              dense
              v-model="search"
              debounce="500"
              filled
              placeholder="Поиск"
              style="width: 300px; background-color: #ffffff;"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </q-toolbar>
      </q-header>

      <q-page-container>
        <q-page>
            <div class="row justify-center q-gutter-md q-pa-md">

            <unit-preview v-for="item in filteredItems" :key="`${item.id}_${item.date_updated}`"
                          :unit="item" :time="time" @openUnitWindow="openUnitWindow"/>

              <unit-dialog :id="id" :openUnit="openUnit" v-on:close-unit-dialog="openUnit = false"/>

<!--              <q-dialog v-model="openMap">-->
<!--                <div style="position: relative; max-width: none; width: 50%">-->
<!--                  <my-map :unit="unit"/>-->
<!--                </div>-->
<!--              </q-dialog>-->
          </div>
        </q-page>
      </q-page-container>

    </q-layout>
  </q-page>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UnitPreview from "../components/UnitPreview";
import UnitDialog from "../components/UnitDialog";
import _ from "lodash";

const UPDATE_TIMEOUT = 5000;

export default {
  name: "Units",
  components: {UnitPreview, UnitDialog},
  data() {
    return {
      items: [],
      positions: [],
      search: '',
      itemUpdated: {},
      // is_destroyed: false,
      defaultTypeSort: 'по созданию',
      typesSort: ['по созданию', 'по имени', 'по связи'],
      setUpdate: null,
      time: new Date(),
      lastFetch: null,
      id: null,
      openUnit: false,
      // openMap: false
    }
  },

  async mounted() {
    this.setTitle('Транспорт')
    await this.fetchUnits()
    this.setUpdate = setInterval(this.update, UPDATE_TIMEOUT)
  },

  destroyed() {
    // this.is_destroyed = true;
    clearInterval(this.setUpdate)
  },

  methods: {
    ...mapActions({
      'setTitle': 'layout/setToolbarTitle',
      // 'setNow': 'units/setNow'
    }),

    update() {
      if(Date.now() - this.lastFetch < UPDATE_TIMEOUT) return;
      this.time = new Date()
      this.updateUnits()
    },

    async fetchUnits() {
      let result = null

      try {
        result = await this.server.items('unit').readMany({
          limit: -1,
          sort: '-date_created',
          fields: ['*', 'device.ident', 'cameras.*', 'cameras.last_snapshot.image.id', 'cameras.last_snapshot.image.storage', 'position.*']
        })
      }
      catch (e) {
        console.debug('fetching error', e)
        this.$q.notify({
          message: `Ошибка получения объектов: ${e.message}`
        })
      }

      if(result) {
        this.items = result.data
      }
      console.debug(this.items)
      this.lastFetch = Date.now()
    },

    async updateUnits() {
      let result

      const lastUpdate = (new Date(this.lastFetch)).toISOString()

      const date_updated = { _gte: lastUpdate }
      const date_created = { _gte: lastUpdate }

      try {
        result = await this.server.items('unit').readMany({
          limit: -1,
          sort: '-date_created',
          fields: ['*', 'device.ident', 'cameras.*', 'cameras.last_snapshot.image.id', 'cameras.last_snapshot.image.storage', 'position.*'],

          filter: {
            _or: [
              {
                date_updated,
              },
              {
                date_created
              },
              {
                cameras: {
                  date_updated
                }
              },
              {
                position: {
                  date_updated
                }
              },
              {
                cameras: {
                  date_created
                }
              }
            ]
          }
        })

        // console.debug('updated result:', result)
      }
      catch (e) {
        console.debug('updating error', e)
      }

      this.lastFetch = Date.now()
      if(!result?.data?.length) return

      for(const unit of result.data) {
        const old = this.items.find(x => x.id === unit.id)

        if(!old) {
          this.items.push(unit)
          continue
        }

        this.items.splice(this.items.indexOf(old), 1, unit)
      }
    },

    // linkToItem(id) {
    //   return `/units/${id}`
    // },

    openUnitWindow(value) {
      this.openUnit = value.openUnit
      this.id = value.id
    }
  },

  computed: {
    ...mapGetters({
      'server': "server/instance"
    }),

    filteredItems() {
      const search = this.search.toLowerCase()

      const items = !this.search ? this.items
          : this.items.filter(x => x.name.toLowerCase().includes(search) || x.device?.ident?.includes(search))

      if(this.defaultTypeSort === 'по имени') return _.sortBy(items, x => x.name)
      if(this.defaultTypeSort === 'по связи') return _.sortBy(items, x => new Date(x.position[0]?.date_updated ?? 0)).reverse()
      else return items
    },
  }
}
</script>

<style scoped>

</style>