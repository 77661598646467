import { render, staticRenderFns } from "./UnitPreview.vue?vue&type=template&id=10ee479e&scoped=true&"
import script from "./UnitPreview.vue?vue&type=script&lang=js&"
export * from "./UnitPreview.vue?vue&type=script&lang=js&"
import style0 from "./UnitPreview.vue?vue&type=style&index=0&id=10ee479e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10ee479e",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QBadge,QIcon,QAvatar,QTooltip,QDialog,QBtn,QBar,QSpace,QPageSticky});qInstall(component, 'directives', {ClosePopup});
