import Vue from 'vue';
import App from './App.vue';
import './quasar'
import router from './router';
import store from './store';
import moment from "moment";

import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';

// import Vue2Leaflet from 'vue2-leaflet';
// import L from 'leaflet';
// import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
// import 'leaflet/dist/leaflet.css';

// import VueApexCharts from 'vue-apexcharts'
// Vue.use(VueApexCharts)

// Vue.component('apexchart', VueApexCharts)


// код для дефолтной иконки маркера на карте
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  // iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // iconUrl: require('leaflet/dist/images/truck-solid.svg'),
  iconUrl: require('./assets/truck-solid.svg'),
  // shadowUrl: require('leaflet/dist/images/base-shadow2.png'),
  shadowUrl: null,
});



// Vue.component('l-map', LMap);
// Vue.component('l-tile-layer', LTileLayer);
// Vue.component('l-marker', LMarker);

Vue.config.productionTip = false

moment.locale('ru')

// new Vue({
//   router,
//   store,
//   Vue2Leaflet,
//   L,
//   render: h => h(App)
// }).$mount('#app')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.use(VuePlyr, {
  plyr: {},
})

// Vue.use(Vue2Leaflet)
// Vue.use(L);