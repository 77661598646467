import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import LoadTasks from "../views/LoadTasks";
import Units from "../views/Units";
import Unit from "../views/Unit";
import Dashboard from "../views/Dashboard";
import About from "../views/About";
import MyMap from "../views/MyMap";
import Devices from "../views/Devices";
import Tracks from "@/views/Tracks";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),

    children: [
      { path: '/tasks', component: LoadTasks },

      { path: '/units', component: Units },

      { path: '/units/:id', component: Unit, props: true },

      { path: '', component: Dashboard },

      { path: '/map', component: MyMap },

      { path: '/devices', component: Devices },

      { path: '/tracks', component: Tracks },

      { path: '/about', component: About },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

let firstRun = true

router.beforeEach(async (to, from, next) => {
  // const authenticated = store.getters['server/authenticated']
  const instance = store.getters["server/instance"]
  const authenticated = instance.auth?.token

  if(firstRun) {
    console.debug('first run')
    firstRun = false

    try {
      const result = await instance.auth.refresh(true)
      console.debug('refresh result', result)
    }
    catch (e) {
      console.debug('cannot refresh with first run, go to login')
      localStorage.removeItem('auth_token')
      localStorage.removeItem('auth_expires')
      localStorage.removeItem('auth_refresh_token')
      return next({ name: 'Login' })
    }
  }

  console.debug('auth', instance.auth)

  if(to.name === 'Login' && authenticated) {
    console.debug('cannot go to login with authenticated')
    next({ path: '/' })
  }
  else if(to.name !== 'Login' && !authenticated) {
    console.debug('no authenticated, go to login page')
    next({ name: 'Login' })
  }
  else next()
})

export default router
