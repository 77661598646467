const state = () => ({
    values: {}
})

const getters = {
    values: state => { return state.values },

    getAddress: getters => unit => {
        return getters.values[unit.id]?.address
    },

    getPosition: getters => unit => {
        return getters.values[unit.id]?.position
    },

    now: state => { return state.now }
}

const actions = {
    setAddress({commit}, value) {
        commit('setAddress', value)
    },

    setPosition({commit}, value) {
        commit('setPosition', value)
    }
}

const mutations = {
    setAddress(state, {unit, value}) {
        if(!state.values[unit.id]) state.values[unit.id] = {}

        const unitValue = state.values[unit.id]
        unitValue.address = value
        delete state.values[unit.id]
        state.values[unit.id] = unitValue
    },

    setPosition(state, {unit, position}) {
        if(!state.values[unit.id]) state.values[unit.id] = {}
        state.values[unit.id].position = position
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
