import { render, staticRenderFns } from "./About.vue?vue&type=template&id=e3fa12f0&scoped=true&"
import script from "./About.vue?vue&type=script&lang=js&"
export * from "./About.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3fa12f0",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QPageScroller from 'quasar/src/components/page-scroller/QPageScroller.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QLayout,QDrawer,QScrollArea,QIcon,QBtn,QList,QItem,QItemSection,QPageContainer,QPageSticky,QPageScroller});qInstall(component, 'directives', {Ripple});
