<template>

<!--  <q-page style="min-height: calc(100vh - 30px);">-->

    <q-page class="custom-unit-q-page">

      <div style="max-width: 100%">

          <q-tabs
              v-model="tab"
              dense
              class="text-grey"
              active-color="primary"
              indicator-color="primary"
              align="justify"
              narrow-indicator
              style="background-color: #ffffff;"
              inline-label
          >
            <q-tab icon="edit" name="edit-object" label="Редактировать" />
            <q-tab icon="camera" name="cameras" label="Камеры" />
            <q-tab icon="images" name="snapshots" label="Снимки" />
            <q-tab icon="tasks" name="tasks" label="Задания" />
            <q-tab icon="construction" name="tools" label="Инструменты" />
          </q-tabs>
          <q-separator style="margin: 0"/>
          <q-tab-panels v-model="tab" animated style="background: transparent">

            <q-tab-panel name="edit-object" style="padding: 0">
<!--              <edit-object :id="id" @updated="fetch"/>-->
              <edit-object :id="id" @updated="updated"/>
<!--              <edit-object :id="id"/>-->
            </q-tab-panel>

            <q-tab-panel name="cameras" class="custom-q-tab-panel" style="padding: 0">
              <cameras :id="id"/>
            </q-tab-panel>

            <q-tab-panel name="snapshots" style="padding: 0">
              <snapshots :id="id"/>
            </q-tab-panel>

            <q-tab-panel name="tasks" class="custom-q-tab-panel" style="padding: 0">
              <load-tasks :id="id" style="padding: 0"/>
            </q-tab-panel>

            <q-tab-panel name="tools" style="padding: 0">
              <tools :id="id"/>
            </q-tab-panel>
          </q-tab-panels>
      </div>

  </q-page>

</template>

<script>
import {mapGetters, mapActions} from "vuex";
import EditObject from "./EditObject";
import Snapshots from "./Snapshots";
import LoadTasks from "./LoadTasks";
import Tools from "../components/Tools";
import Cameras from "./Cameras";

export default {
  name: "Unit",
  components: {Cameras, LoadTasks, EditObject, Snapshots, Tools},
  // props:  ['id'],
  props: {
  //   unit: {
  //     required: true,
  //     type: Object
  //   },
    id: {
      required: false,
      type: Number
    }
  },

  data() {
    return {
      // unit: null,
      tab: 'cameras',
      creating: false,
    }
  },

  async mounted() {
    // await this.fetch()
  },

  computed: {
    ...mapGetters({
      'server': 'server/instance'
    }),
  },

  methods: {
    ...mapActions({
      'setTitle': 'layout/setToolbarTitle'
    }),

    // async fetch() {
    //   this.unit = await this.server.items('unit').readOne(this.id)
    // },

    updated() {
      this.$emit('updated')
    }
  }
}
</script>

<style scoped>
.custom-unit-q-page {
  height: auto;
  min-height: auto!important;
}
/*.custom-unit-q-page {*/
/*  min-height: auto!important;*/
/*}*/
</style>