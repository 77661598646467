import Vue from 'vue'

import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'

import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';

Vue.use(Quasar, {
    config: {
        notify: {},
    },
    plugins: {
      Notify
    },

 })