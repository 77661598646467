<template>
  <div class="q-pa-md row justify-center items-center" style="min-height: calc(100vh - 69px)">
<!--    <div v-if="showPreload" class="row items-center justify-center" style="min-height: calc(100vh - 50px)">-->
      <lottie-animation
          v-if="showPreload"
          ref="anim"
          :animationData="require('../assets/preload-animation.json')"
          :loop="true"
          :autoPlay="true"
          :speed="1"
          style="width: 200px; height: 200px"
      />
<!--    </div>-->
    <q-form v-else @submit.prevent="saveChange" style="background-color: #ffffff; min-width: 450px; padding: 40px 40px">
      <template v-for="item in fieldsForUpdate">
        <q-input
            v-if="checkInterface(item)"
            filled
            clearable
            v-model="value[item]"
            :label="getRuNameFields(item)"
            style="padding-bottom: 30px"
            lazy-rules
            :rules="[ val => val && val.trim().length > 0 || 'Пожалуйста, введите имя объекта!']"
        >
<!--          :rules="[ val => val && val.label.trim().length > 0 || 'Пожалуйста, введите имя объекта!']"-->


<!--          <template v-if="checkInterface(item)" v-slot:after>-->
<!--            <q-btn round dense flat icon="link_off" @click="linkOff(item)">-->
<!--              <q-tooltip>Отвязать</q-tooltip>-->
<!--            </q-btn>-->
<!--          </template>-->
        </q-input>
  <!--      <q-input v-for="(item, index) in fieldsForUpdate"-->
  <!--               :key="index"-->
  <!--               v-model.trim="modelsForForm[item]"-->
  <!--               :label="getRuNameFields(item)"-->
  <!--               :type="getType(item)"-->

  <!--               style="padding-bottom: 20px"/>-->
  <!--      :rules="getRules(item)"-->





<!--        v-model="{value: value[item], label: getIdentType(value[item])}"-->
<!--        v-model="getIdentType(value[item])"-->
            <template v-else>
              <q-select
                  filled
                  clearable
                  v-model="modelsForForm.currentDevice"
                  :label="getRuNameFields(item)"
                  style="padding-bottom: 30px"
                  lazy-rules
                  :rules="[ val => val || 'Пожалуйста, введите устройство объекта!']"
                  readonly
              >

<!--                :rules="[ val => val && val.label.trim().length > 0 || 'Пожалуйста, введите имя объекта!']"-->
                <template v-slot:after>
                  <q-btn round dense flat icon="link_off" @click="linkOff(item)">
                    <q-tooltip>Отвязать устройство</q-tooltip>
                  </q-btn>
                </template>
              </q-select>

              <q-select
                  clearable
                  filled
                  bottom-slots
                  v-model="modelsForForm.nextDevice"
                  :options="freeDevices"
                  label="Свободные устройства"
              >
                <template v-slot:after>
                    <q-btn round dense flat icon="link" :disable="(!modelsForForm.nextDevice || typeof value[item] === 'number')" @click="linkOn(item)">
                    <q-tooltip>Привязать устройство</q-tooltip>
                  </q-btn>
                </template>
              </q-select>
            </template>

      </template>

      <div style="padding-top: 10px">
        <q-btn color="primary" type="submit">Сохранить</q-btn>
<!--        <q-btn color="primary" type="button" @click.prevent="saveChange" :disable="canChange">Сохранить</q-btn>-->
        <q-btn color="primary" flat class="q-ml-sm" @click="reset">Отмена</q-btn>
      </div>

    </q-form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import {dateIsValid} from "../utils";
import LottieAnimation from 'lottie-web-vue';
import _ from "lodash";

export default {
  name: "EditObject",
  components: {
    LottieAnimation
  },
  // props: ['id'],
  // props: {
    // unit: { // todo: изменить на обычный идентификатор
    //   type: Object,
    //   required: true
    // }
  // },
  props: {
    id: {
      required: true,
      type: Number
    }
  },

  data() {
    return {
      // id: null,
      showPreload: true,
      unit: null,
      value: null,
      fields: [],
      updatePermissions: null,
      fieldsForUpdate: [],
      modelsForForm: {
        currentDevice: null,
        nextDevice: null
      },
      devices: [],
      freeDevices: []
    }
  },

  async mounted() {
    // this.id = this.unit.id

    // this.value = {
    //   ...this.unit
    // }

    // console.debug(this.value)

    this.updatePermissions = this.unitPermissions.find(item => item.action === 'update')
    if (this.updatePermissions) {
      this.fieldsForUpdate = this.updatePermissions.fields.slice()

      // const cameraIndex = this.fieldsForUpdate.findIndex(item => item === 'cameras') // для теста!!!
      // if(cameraIndex !== -1) {
      //   this.fieldsForUpdate.splice(cameraIndex, 1)  // для теста!!!
      // }
    }

    await this.fetch()

    this.value = {
      ...this.unit
    }

    await this.fetchFields()


    // for (let key in this.modelsForForm) {
    //   this.modelsForForm[key] = this.value[key]
    // }

    // this.value = {
    //   ...this.unit
    // }
    // this.value = this.unit




    if (this.value.device) {
      await this.fetchDevices()
      const currentDevice = this.devices.find(item => item.id === this.value.device)
      // console.debug(currentDevice)
      this.modelsForForm.currentDevice = {
        value: currentDevice.id,
        label: `${currentDevice.ident + ' - ' + currentDevice.type}`
      }
    }


    this.showPreload = false
  },

  computed: {
    ...mapGetters({
      'server': 'server/instance',
      'unitPermissions': 'server/unitPermissions'
    }),

    // canChange() {
    //   return !(this.value.name && this.value.name.trim().length > 0)
    // },


  },

  methods: {
    async fetch() {
      try {
        this.unit = await this.server.items('unit').readOne(this.id, {
          // fields: ['id', 'name', 'device.ident', 'device.type', 'device.id']
          // fields: ['id', 'name', 'device']
          fields: this.fieldsForUpdate
        })
        console.debug(this.unit)
      }
      catch (e) {
        console.debug('fetching error', e)
        this.$q.notify({
          message: `Ошибка получения объекта: ${e.message}`
        })
      }
    },

    async saveChange() {
      // const item = {}
      //
      // this.fieldsForUpdate.forEach(x => {
      //   item[x] = this.value[x]
      // })
      //
      // console.debug(item)

      try {
        await this.server.items('unit').updateOne(this.id, this.value)
        // await this.server.items('unit').updateOne(this.value.id, item)
        // await this.server.items('unit').updateOne(this.value.id, { name: this.value.name })
        this.$emit('updated')
        this.$q.notify({
          message: 'Успешно сохранено!',
          color: 'primary'
        })
      }
      catch (e) {
        console.debug('fetching error', e)
        this.$q.notify({
          message: `Ошибка обновления объекта: ${e.message}`
        })
      }
      await this.fetch()
      await this.fetchDevices()
      this.modelsForForm.nextDevice = null
    },

    reset() {
      this.value.name = this.unit.name

      if (this.value.device) {
        this.value.device = this.unit.device
        const currentDevice = this.devices.find(item => item.id === this.value.device)
        // console.debug(currentDevice)
        this.modelsForForm.currentDevice = {
          value: currentDevice.id,
          label: `${currentDevice.ident + ' - ' + currentDevice.type}`
        }
      }

      this.modelsForForm.nextDevice = null
    },

    linkOff(key) {
      this.value[key] = null
      this.modelsForForm.currentDevice = null
    },

    linkOn(key) {
        this.value[key] = this.modelsForForm.nextDevice.value
        this.modelsForForm.currentDevice = {
          label: this.modelsForForm.nextDevice.label,
          value: this.modelsForForm.nextDevice.value
        }
    },

    async fetchFields() {
      const result = await this.server.fields.readMany('unit')
      this.fields = result
    },

    getType(value) {
      const currentField = this.fields.find(item => item.field === value)
      if(currentField.type === 'string') {
        return 'text'
      }
      else {
        return 'number'
      }
    },

    getRuNameFields(item) {
      if(item === 'name') return 'Имя объекта'
      if(item === 'device') return 'Текущее устройство'
      else return item
    },

    getRules(value) {
      const currentField = this.fields.find(item => item.field === value)
      const rules = []
      if(!currentField.schema.is_nullable) {
        // console.debug('requier')
        rules.push(val => val && val.trim().length > 0 || 'Пожалуйста заполните обязательное поле!')
      }
      if(currentField.schema.max_length) {
        // console.debug('max_length')
        rules.push(val => val.trim().length <= currentField.schema.max_length  || `Значение должно содержать не более ${currentField.schema.max_length} символов!`)
      }
      return rules
    },

    async fetchDevices() {
      let result = null

      try {
        result = await this.server.items('device').readMany({
          limit: -1,
          // sort: '-date_created',
          fields: ['id', 'unit', 'ident', 'type']
        })
      }
      catch (e) {
        console.debug('fetching error', e)
        this.$q.notify({
          message: `Ошибка получения объектов: ${e.message}`
        })
      }

      if(result) {
        this.devices = result.data
        const freeDevices = this.devices.filter(item => item.unit.length === 0)
        this.freeDevices = freeDevices.map(item => {
          return {
            label: `${item.ident + ' - ' + item.type}`,
            value: item.id
          }
          // return item.id
        })
      }
      console.debug(this.devices)
      console.debug(this.freeDevices)
    },

    checkInterface(value) {
      const currentField = this.fields.find(item => item.field === value)
      if (currentField.field !== 'device' || currentField.meta.display !== 'related-values') {
          return true
        }


      // if (currentField.meta.display !== 'related-values') {
      //   return true
      // }
      else return false
    },

    // getIdentType(value) {
    //   const currentDevice = this.devices.find(item => item.id === value)
    //   this.currentDevice = {
    //     value: currentDevice.id,
    //     label: `${currentDevice.ident + ' - ' + currentDevice.type}`
    //   }
    //    return this.currentDevice
    // }
  }
}
</script>

<style scoped>
/*.unit__img {*/
/*  cursor: pointer;*/
/*}*/
/*.for-padding * .q-page-container {*/
/*  padding-top: 64px !important;*/
/*}*/
/*.custom-q-pa-md {*/
/*  padding: 16px 10px;*/
/*}*/
</style>