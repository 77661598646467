<template>
  <div class="q-pa-md column" style="min-height: calc(100vh - 69px)">

    <q-card style="width: 40%; align-self: center; margin-bottom: 30px; background-color: white">

      <q-card-section style="padding-bottom: 0">
        <div class="text-h6">Выберите диапазон</div>
      </q-card-section>

      <q-card-section style="padding-bottom: 0">
        <q-form>
          <q-input label="дата" filled v-model="createData.date" mask="date" :rules="['date', getDatesRange]">
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                  <q-date v-model="createData.date" :options="getDatesRange">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Закрыть" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
            <div class="row justify-evenly" style="margin-bottom: 15px">
              <q-input :rules="[ val => createData.fullBeginDate < createData.fullEndDate || 'Начало не должно быть позже конца']" filled type="time" name="beginTime" v-model="createData.beginTime" @input="setDate()" style="cursor: pointer" hint="Начало" />
              <q-input :rules="[ val => createData.fullEndDate > createData.fullBeginDate || 'Конец не должен быть раньше начала']" filled type="time" name="endTime" v-model="createData.endTime" @input="setDate()" style="cursor: pointer" hint="Конец" />
            </div>
            <div class="q-pa-md row justify-evenly">
              <q-select
                  class="col-8"
                  filled
                  v-model="camera"
                  clearable
                  label="Выберите камеру"
                  :options="cameras"
                  @filter-abort="()=>{}"
                  style="width: 250px"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Никаких результатов
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
        </q-form>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="OK" color="primary" @click="getFiles" :disable="canCreate"/>
      </q-card-actions>

    </q-card>

    <q-table
        title="Файлы"
        :data="files"
        :columns="columns"
        row-key="name"
        :loading="loading"
        no-data-label="Нет файлов"
        loading-label="Загрузка"
        rows-per-page-label="Количество отбражаемых файлов"
        :pagination-label="customPagination"
        class="my-sticky-header-table"
    >
      <template v-slot:loading>
        <q-inner-loading showing color="primary" />
      </template>
    </q-table>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { humanFileSize } from '../utils';
import moment from "moment";
import {dateIsValid} from "../utils";


export default {
  name: "Tools",
  props: ['id'],
  // props: {
    // unit: {
    //   type: Object,
    //   required: true
    // }
    // id: {
    //   type: Number,
    //   required: true
    // }
  // },

  data() {
    return {
      unit: null,
      files: [],
      columns: [
        {name: 'index', required: true, label: '#', align: 'left', field: 'index', sortable: true},
        { name: 'bytes',required: true, label: 'Размер файла', align: 'left', field: 'bytes', format: val => humanFileSize(val / 1000, true), sortable: true },
        { name: 'channel',required: true, label: 'Камера', align: 'left', field: 'channel', format: val => {
          let newVal;
          this.cameras.forEach(item => {
            console.debug(item)
            if (item.channel === val) {
              newVal = item.label
            }
          })
            return newVal
          },
          sortable: true },
        { name: 'begin',required: true, label: 'Начало', align: 'left', field: 'date', format: val => this.getTimestamp(val), sortable: true },
        { name: 'end',required: true, label: 'Конец', align: 'left', field: 'date', format: (val, row) => this.getTimestamp(val, row.seconds), sortable: true },
        { name: 'seconds',required: true, label: 'Продолжительность', align: 'left', field: 'seconds', format: val => moment.duration(val*1000).humanize(), sortable: true },
      ],
      loading: false,
      createData: {
        beginTime: '00:00',
        endTime: '23:59',
        fullBeginDate: new Date(Date.now() - 1 * 60 * 1000),
        fullEndDate: new Date(),
        date: moment().format('YYYY/MM/DD')
      },
      cameras: null,
      camera: null
    }
  },

  async mounted() {
    await this.fetch()
    await this.fetchCamera()
  },

  watch: {

  },

  computed: {
    ...mapGetters({
      'server': 'server/instance'
    }),
    canCreate() {
      const data = this.createData
      return !(data.date && !this.loading && dateIsValid(data.fullBeginDate) && dateIsValid(data.fullEndDate) && data.fullBeginDate < data.fullEndDate)
    },
  },

  methods: {
    async fetch() {
      this.unit = await this.server.items('unit').readOne(this.id, {
        // fields: ['id', 'name', 'device.ident', 'device.type', 'device.id']
        fields: ['device']
      })
    },

    customPagination(firstRowIndex, endRowIndex, totalRowsNumber) {
      return `${firstRowIndex}-${endRowIndex} из ${totalRowsNumber}`
    },

    getTimestamp(value, seconds = 0) {
      const utc = ( new Date(value) ).getTime() - (3 * 60 * 60 * 1000) + (seconds * 1000)
      const date = new Date(utc)
      // return moment(date).format('YYYY-MM-DD HH:mm:ss')
      return moment(date).format('LLL')
    },

    getDatesRange(date) {
      const now = moment().format('YYYY/MM/DD')
      console.debug('now:', now)
      return date <= now
    },

    // resetCreationData() {
    //   this.createData = {
    //     beginTime: '00:00',
    //     endTime: '23:59',
    //     fullBeginDate: new Date(Date.now() - 1 * 60 * 1000),
    //     fullEndDate: new Date(),
    //     date: moment().format('YYYY/MM/DD')
    //   }
    // },

    getRightTime() {
      const data = this.createData
      const partsBeginTime = data.beginTime.split(':');
      const partsEndTime = data.endTime.split(':');

      const createDate = new Date();
      const rightBeginTime = new Date(createDate.setHours(parseInt(partsBeginTime[0]), parseInt(partsBeginTime[1]), 0));
      const rightEndTime = new Date(createDate.setHours(parseInt(partsEndTime[0]), parseInt(partsEndTime[1]), 0));
      console.debug(rightBeginTime);
      // console.debug(rightEndTime);
      return {
        rightEndTime,
        rightBeginTime
      }
    },

    setDate() {
      const fullRightTime = this.getRightTime()

      console.debug(fullRightTime)

      this.createData.fullBeginDate = fullRightTime.rightBeginTime;
      this.createData.fullEndDate = fullRightTime.rightEndTime;
    },

    async getFiles() {
      this.loading = true

      const fullRightTime = this.getRightTime()

      const beginTime = moment(fullRightTime.rightBeginTime).format('HH:mm:ss')
      const endTime = moment(fullRightTime.rightEndTime).format('HH:mm:ss')

      const begin = this.createData.date.replace(/\//g, '-')
      const end = this.createData.date.replace(/\//g, '-')

      this.createData.fullBeginDate = `${begin}T${beginTime}`;
      this.createData.fullEndDate = `${end}T${endTime}`;

      console.debug('creation times:', {
        beginTime, endTime,
        begin, end
      })

      console.debug(this.camera)

      const body = {
        begin: this.createData.fullBeginDate,
        end: this.createData.fullEndDate,
        channel: this.camera ? this.camera.channel : undefined
      }

      try {
        const result = await this.server.transport.post(`/device/${this.unit.device}/files`, body)
        this.files = result.data.reverse()

        this.files.forEach((item, index) => {
          item.index = index + 1
        })

        console.debug(this.files)
        // this.resetCreationData()
      }
      catch (e) {
        console.error('fetch error', e)
        this.$q.notify({
          message: `Ошибка получения файлов: ${e.message}`,
          color: 'red'
        })
      }
      finally {
        this.loading = false
      }
    },

    async fetchCamera() {
      const filter = {
        unit: {
          id: {
            _eq: this.id
          }
        }
      }
      const result = await this.server.items('camera').readMany({
        filter: filter,
        limit: -1,
        sort: ['-channel'],
        fields: ['id', 'name', 'channel']
      })
      const prepared = result.data.map(x => {
        return {
          label: x.name ? `${x.name} (${x.channel})` : `канал: ${x.channel}`,
          value: x.id,
          channel: x.channel
        }
      })
      this.cameras = prepared
    },
  }
}
</script>

<style lang="sass">
@import "src/styles/quasar.variables.sass"

.my-sticky-header-table
  /* height or max-height is important */
  height: 50vh
  width: 80%
  align-self: center

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    /* bg color is important for th; just specify one */
    background-color: white

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
</style>