<template>
  <q-page class="custom-q-page" style="background-color: whitesmoke;">
    <q-layout view="hhh LpR ffr" container class="custom-q-layout">

      <q-header class="border-for-header" style="background-color: #ffffff;">
        <q-toolbar class="row items-center justify-between">
          <q-btn-toggle
              v-model="status"
              toggle-color="primary"
              :options="listStatuses"
              flat
              no-caps
              color="black"
              class="custom-q-btn-toggle"
          >

            <template v-slot:one>
              <q-badge rounded floating color="primary">{{statuses.queue}}</q-badge>
            </template>

            <template v-slot:two>
              <q-badge rounded floating color="primary">{{statuses.done}}</q-badge>
            </template>

            <template v-slot:three>
              <q-badge rounded floating color="primary">{{statuses.progress}}</q-badge>
            </template>

            <template v-slot:four>
              <q-badge rounded floating color="primary">{{statuses.error}}</q-badge>
            </template>

            <template v-slot:five>
              <q-badge rounded floating color="primary">{{statuses.all}}</q-badge>
            </template>

          </q-btn-toggle>

          <q-select dense borderless v-model="selectedSort" :options="listSorts" label="Выберите сортировку" style="width: 280px">

            <template v-if="directionSort === 'right'" v-slot:after>
              <q-btn dense flat icon="sort" style="transform: scaleY(-1)" @click="chooseDirectionSort()">
                <q-tooltip>
                  Направление сортировки
                </q-tooltip>
              </q-btn>
            </template>

            <template v-else v-slot:after>
              <q-btn dense flat icon="sort" @click="chooseDirectionSort()">
                <q-tooltip>
                  Направление сортировки
                </q-tooltip>
              </q-btn>
            </template>

          </q-select>

          <q-input
              dense
              v-model="search"
              debounce="500"
              filled
              placeholder="Поиск"
              style="width: 300px; background-color: #ffffff;"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </q-toolbar>
      </q-header>

      <q-footer class="border-for-footer" style="background-color: #ffffff;">
        <q-toolbar class="justify-center">
          <q-pagination
              v-model="currentPage"
              :max="countPages"
              :max-pages="5"
              direction-links
              :boundary-numbers="false"
              boundary-links
          />
        </q-toolbar>
      </q-footer>

      <q-page-container>
        <q-page>

          <div v-if="showPreload" class="row items-center justify-center" style="min-height: calc(100vh - 180px)">
            <lottie-animation
                ref="anim"
                :animationData="require('../assets/preload-animation.json')"
                :loop="true"
                :autoPlay="true"
                :speed="1"
                style="width: 200px; height: 200px"
            />
          </div>

          <div v-else class="row justify-center">
            <load-task-preview @updated="taskUpdated" v-for="item in items" :key="item.date_updated" :item="item" class="padding"/>
<!--            <load-task-preview :show-video="isShow(item)" @updated="taskUpdated" v-for="item in items" :key="item.date_updated" :item="item" class="padding"/>-->
<!--            <load-task-preview @show="onShowVideo" :show-video="isShow(item)" @updated="taskUpdated" class="padding" v-for="item in items" :key="item.date_updated" :item="item"/>-->
          </div>

          <q-dialog v-model="creating">
            <load-task-create-form :id="id" @created="created"/>
          </q-dialog>

          <q-page-sticky position="bottom-right" :offset="[18, 18]">
            <q-btn fab icon="add" color="secondary" @click="creating = true"/>
          </q-page-sticky>

        </q-page>
      </q-page-container>
    </q-layout>
  </q-page>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {humanFileSize, perPageInt, countPages} from '../utils';
import LoadTaskPreview from "../components/LoadTaskPreview";
import LoadTaskCreateForm from "../components/LoadTaskCreateForm";
import LottieAnimation from 'lottie-web-vue';

const selectedSortKey = 'tasks_selectedSort';
const directionSortKey = 'tasks_directionSort';
// const searchKey = 'tasks_search';

export default {
  name: "LoadTasks",
  components: {LoadTaskCreateForm, LoadTaskPreview, LottieAnimation},
  // props: ['id'],

  props: {
    id: {
        required: false,
        type: Number
      }

      // unit: {
      //   required: false,
      //   type: Object
      // }
    }
  ,

  data() {
    return {
      items: [],
      creating: false,
      videoShow: '',
      showPreload: true,
      search: '',
      currentPage: 1,
      perPage: '12',
      count: 0,
      status: null,
      listStatuses: [{label: `в очереди`, value: 'queue', slot: 'one'},
        {label: 'завершено', value: 'done', slot: 'two'},
        {label: 'выполняется', value: 'progress', slot: 'three'},
        {label: 'ошибка', value: 'error', slot: 'four'},
        {label: 'все', value: null, slot: 'five'}],
      statuses: {
        all: 0,
        queue: 0,
        progress: 0,
        done: 0,
        error: 0
      },
      selectedSort: localStorage[selectedSortKey] ? JSON.parse(localStorage[selectedSortKey]) : {label: 'по дате создания', value: 'date_created'},
      listSorts: [
        {label: 'по дате создания', value: 'date_created'},
        {label: 'по дате интервала', value: 'begin'}
      ],
      directionSort: localStorage[directionSortKey] ?? 'right',
    }
  },

  async mounted() {
    // if(this.unit) {
    //   this.setTitle(this.unit.name)

    // }
    if(!this.id) {
      this.setTitle('Задания')
      // this.search = localStorage[searchKey] ?? ''
    }
    await this.fetch()
    await this.fetchStatuses()
    this.setDisabledForButton()
    this.showPreload = false
  },

  // destroyed() {
  //   this.setTitle(null)
  // },

  computed: {
    ...mapGetters({
      'server': 'server/instance'
    }),

    // searchKey() {
    //   return `${searchKey}_${this.unit.id}`
    // },

    fetchFilter() {
      const filter = {
        _and: []
      }

      if(this.id) {
        filter._and.push({
          camera: {
            unit: { _eq: this.id }
          },
        })
      }

      if(this.status) {
        filter._and.push({
          status: {_eq: this.status}
        })
      }

      if(this.search) {
        const search = [
          { description: {_contains: this.search} },
          {
            camera: {
              name: { _contains: this.search }
            }
          }
        ]

        filter._and.push({
          _or: search
        })

        if(!this.id) search.push({
          camera: {
            unit: {
              name: {_contains: this.search}
            }
          }
        })
      }

      return filter
    },

    countPages() {
      return countPages(this.count, this.perPageInt)
    },
    perPageInt() {
      return perPageInt(this.perPage)
    },
  },

  watch: {
    async search(n) {
      this.showPreload = true
      // if (this.unit) {
      //   if (!n) localStorage.removeItem(this.searchKey)
      //   else localStorage[this.searchKey] = n
      // }
      // else {
      //   localStorage[searchKey] = n
      // }
      await this.fetch()
      await this.fetchStatuses()
      this.setDisabledForButton()
      this.showPreload = false
    },

    async currentPage() {
      this.showPreload = true
      await this.fetch()
      this.showPreload = false
    },

    async status(n) {
      console.debug(this.status)
      this.showPreload = true
      await this.fetch()
      this.showPreload = false
    },

    async selectedSort(n) {
      this.showPreload = true
      console.debug(this.selectedSort)
      localStorage[selectedSortKey] = JSON.stringify(n)
      await this.fetch()
      this.showPreload = false
    },

    directionSort(n) {
        localStorage[directionSortKey] = n;
    }
  },

  methods: {
    ...mapActions({
      'setTitle': 'layout/setToolbarTitle'
    }),

    taskUpdated(item) {
      const old = this.items.find(x => x.id === item.id)

      // console.debug(old, item, result)

      const index = this.items.indexOf(old)

      item.camera = old.camera
      item.file = old.file

      this.items.splice(index, 1, item)
    },

    setDisabledForButton() {
      const badges = document.querySelectorAll('.custom-q-btn-toggle * .q-badge')
      // console.debug(badges)
      badges.forEach(item => {
        const button = item.closest('.q-btn')
        // console.debug(button)
        if(item.textContent === '0') {
          button.setAttribute('disabled', 'disabled')
        }
        else{
          button.removeAttribute('disabled')
        }
      })
    },

    async chooseDirectionSort() {
      this.directionSort = this.directionSort === 'right' ? 'reverse' : 'right'
      console.debug(this.directionSort)
      await this.fetch()
    },

    async created() {
      this.showPreload = true
      await this.fetch()
      this.showPreload = false
      this.creating = false
    },

    // isShow(item) {
    //   return this.videoShow === item.id
    // },

    // onShowVideo(id) {
    //   console.debug('on show', id)
    //   this.videoShow = id
    // },

    // calculateFileSizes(children) {
    //   const data = children.filter(x => x.file)
    //   if(data.length === 0) return '--'
    //
    //   const sum = _.sum(data.map(x => x.file.filesize / 1024)) * 1024
    //   return humanFileSize(sum, true)
    // },

    calculateFileSizes(value) {
      if (value === null) return '--'
      const result = (value.filesize / 1000) * 1024;

      return humanFileSize(result, true);
      // return  result;
    },

    // toTask(id) {
    //   return `/task/${id}`
    // },

    async fetch() {
      let result

      try {
        result = await this.server.items('load_video_task').readMany({
          meta: 'filter_count',
          limit: this.perPageInt,
          page: this.currentPage,
          fields: ['id', 'description', 'camera.unit.name', 'begin', 'end', 'status', 'file.filesize', 'file.storage', 'camera.channel', 'camera.name', 'date_created', 'date_updated'],

          sort: [`${this.directionSort === 'right' ? '-' : ''}${this.selectedSort.value}`],

          filter: this.fetchFilter
        })

        console.debug(result)

        this.count = result.meta.filter_count

        // console.debug(result.meta.filter)
        console.debug(this.count);


      } catch (e) {
        console.error('error fetch tasks', e)
        this.$q.notify({
          message: `Ошибка загрузки данных: ${e.message}`,
          color: 'red'
        })
      }

      this.items = result.data
      console.debug(this.items)
    },

    async fetchStatuses() {
      try {
        const result = await this.server.items('load_video_task').readMany({
          limit: -1,
          fields: ['status'],
          filter: this.fetchFilter
        })

        console.debug(result.data.map(x => x.status))
        const statusesArray = result.data.map(x => x.status)
        this.statuses.all = statusesArray.length
        this.statuses.queue = statusesArray.filter(x => x === 'queue').length
        this.statuses.progress = statusesArray.filter(x => x === 'progress').length
        this.statuses.done = statusesArray.filter(x => x === 'done').length
        this.statuses.error = statusesArray.filter(x => x === 'error').length

      } catch (e) {
        console.error('error fetch statuses', e)
        this.$q.notify({
          message: `Ошибка загрузки данных: ${e.message}`,
          color: 'red'
        })
      }
    },
  }
}
</script>

<style lang="sass" scoped>
@import "src/styles/quasar.variables.sass"

.custom-q-layout
  min-height: calc(100vh - 50px)

.tree-parent > .q-tree__node
  border: 1px solid #eceff1

.tree-parent * .q-tree__node-header
  margin-top: 0

.custom-load-task > .q-item__section
  margin-bottom: 10px

.padding
  margin: 1%
</style>