<template>
<!--  <q-page style="background-color: whitesmoke;">-->
<!--    <q-layout view="hhh LpR ffr" container style="min-height: calc(100vh - 50px);">-->

<!--      <q-page-container>-->
<!--        <q-page>-->
<!--    <q-card>-->
<!--      <q-card-section>-->
  <div style="position: relative;">
      <l-map
          @update:zoom="zoomUpdated"
          @update:center="centerUpdated"
          :style="getStyleMap"
          :zoom="zoom"
          :center="center">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <v-marker-cluster :options="{ maxClusterRadius: 40 }">
          <l-marker
              v-for="marker in positions"
              :key="marker.id"
              :visible="true"
              :draggable="false"
              :lat-lng.sync="(marker.latitude + ', ' + marker.longitude).split(', ').map(Number)"
              @click="choosePoint(marker)"
          >
            <l-tooltip :options="{ permanent: true, direction: 'center', offset: [-15, 40], opacity: 1, className: 'm-custom-tooltip' }" :content="marker.unit.name" />
          </l-marker>
        </v-marker-cluster>
      </l-map>

        <q-scroll-area class="m-custom-control" :style="getStyleControl">
          <q-list dense class="row">
              <q-item
                  v-for="item in positions"
                  :key="item.unit.id"
                  clickable
                  v-ripple
                  :active="activeItem === item.unit.name"
                  @click="choosePoint(item)"
                  active-class="m-custom-active-class"
                  class="m-custom-item"
              >
                <q-item-section>{{item.unit.name}}</q-item-section>
              </q-item>
          </q-list>
        </q-scroll-area>
  </div>
<!--      </q-card-section>-->
<!--    </q-card>-->

<!--        </q-page>-->
<!--      </q-page-container>-->

<!--    </q-layout>-->
<!--  </q-page>-->
</template>

<script>
// import  {latLng} from 'leaflet';
import {LMap, LTileLayer, LMarker, LTooltip, LControl} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import {mapActions, mapGetters} from "vuex";

const zoomMapKey = 'map_zoom';
const centerMapKey = 'map_center';
const UPDATE_TIMEOUT = 5000;

export default {
  name: 'MyMap',
  props: {
    unit: {
      required: false,
      type: Object
    },
    // position: {
    //   required: false,
    //   type: Object
    // },
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LControl,
    'v-marker-cluster': Vue2LeafletMarkerCluster
  },
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      // zoom: parseFloat(localStorage[zoomMapKey] ?? 15),
      zoom: localStorage[zoomMapKey] ? parseFloat(localStorage[zoomMapKey]) : 15,
      // zoom: 15,
      // center: [50.595414, 36.587277],
      center: localStorage[centerMapKey] ? JSON.parse(localStorage[centerMapKey]) : [50.595414, 36.587277],
      // center: this.getCurrentCenter,
      positions: [],
      activeItem: '',
      optionsMarkerCluster: {},
      // icon: L.icon({
      //   iconUrl: '~assets/no-image.png',
      //   iconSize: [32, 37],
      //   iconAnchor: [16, 37]
      // }),
      lastFetch: null,
      setUpdate: null
    }
  },

  async mounted() {
    if(this.unit) {
      const unit = { ...this.unit }
      const pos = unit.position?.[0]
      delete unit.position
      pos.unit = unit
      this.positions = [pos]
    }

    if(!this.unit) {
      this.setTitle('Карта')
      await this.fetchPositions()
      this.setUpdate = setInterval(this.updatePositions, UPDATE_TIMEOUT)
    }

    this.zoom = this.getCurrentZoom
    this.center = this.getCurrentCenter
  },
  destroyed() {
    clearInterval(this.setUpdate)
  },

  watch: {
    unit() {
      const unit = { ...this.unit }
      const pos = unit.position?.[0]
      delete unit.position
      pos.unit = unit
      this.positions = [pos]
    }
  },

  methods: {
    ...mapActions({
      'setTitle': 'layout/setToolbarTitle'
    }),

    zoomUpdated (zoom) {
      if(!this.unit) {
        this.zoom = zoom
        localStorage[zoomMapKey] = this.zoom
      }
    },

    centerUpdated (center) {
      if(!this.unit) {
        this.center = center
        localStorage[centerMapKey] = JSON.stringify(this.center)
        // console.debug(this.center)
      }
      else {
        this.center = center
      }
    },

    async fetchPositions() {
      let result = null
      console.debug(this.positions)
      try {
        result = await this.server.items('unit_position').readMany({
          limit: -1,
          fields: ['id', 'latitude', 'longitude', 'unit.id', 'unit.name'],
          // filter: {
          //   unit: {
          //     _in: this.positions.map(x => x.id)
          //   }
          // }
          // filter: this.fetchFilter
        })
      }
      catch (e) {
        console.debug('fetching error', e)

        this.$q.notify({
          message: `Ошибка получения координат: ${e.message}`
        })

        return
      }

      // console.debug(result.data)
      this.positions = result.data
      console.debug(this.positions)

      // for(const pos of result.data) {
      //   const unit = this.items.find(u => u.id === pos.unit)
      //   if(!unit) continue
      //   unit.position = pos
      // }

      this.lastFetch = Date.now()
      console.debug(this.lastFetch)
    },

    async updatePositions() {
      const lastUpdated = (new Date(this.lastFetch)).toISOString()

      // const filter = {
      //   _or: [
      //     {
      //       date_created: { _gte: lastUpdated }
      //     },
      //     {
      //       date_updated: { _gte: lastUpdated }
      //     }
      //   ]
      // }
      let result = null
      // console.debug(this.positions)
      try {
        result = await this.server.items('unit_position').readMany({
          limit: -1,
          fields: ['id', 'latitude', 'longitude', 'unit.id', 'unit.name'],
          // filter: {
          //   unit: {
          //     _in: this.positions.map(x => x.id)
          //   }
          // }
          // filter: this.fetchFilter
          filter: {
            date_updated: { _gte: lastUpdated }
          }
        })
      }
      catch (e) {
        console.debug('fetching error', e)

        this.$q.notify({
          message: `Ошибка получения координат: ${e.message}`
        })

        return
      }


      // this.positions = result.data
      // console.debug(this.positions)

      this.lastFetch = Date.now()
      if(!result?.data?.length) return

      for(const unit of result.data) {
        const old = this.positions.find(x => x.id === unit.id)

        if(!old) {
          this.positions.push(unit)
          continue
        }

        this.positions.splice(this.positions.indexOf(old), 1, unit)
      }
    },

    // getPosition(item) {
    //   return this.positions.find(x => x.unit === item.id)
    // },


    choosePoint(value) {
      // console.debug(value)
      // if(this.unit) {
      //   this.activeItem = value.name
      //   this.center = (value.position.latitude + ', ' + value.position.longitude).split(', ').map(Number)
      // }
      // else {
        this.activeItem = value.unit.name
        this.center = (value.latitude + ', ' + value.longitude).split(', ').map(Number)
      // }
    },

  },
  computed: {
    ...mapGetters({
      'server': "server/instance"
    }),

    getStyleMap() {
      if(!this.unit) {
        return {
          width: '100%',
          height: 'calc(100vh - 50px)'
        }
      }
      else {
        return {
          width: '100%',
          height: '50vh'
        }
      }
    },

    getStyleControl() {
      if(!this.unit) {
        return {
          minWidth: '400px',
          height: '35.5vh'
        }
      }
      else {
        return {
          minWidth: '200px',
          height: '32px'
        }
      }
    },

    getCurrentZoom() {
      if(this.unit) {
        return 15
      }
      else {
        // return parseFloat(localStorage[zoomMapKey] ?? 15)
        return  localStorage[zoomMapKey] ? parseFloat(localStorage[zoomMapKey]) : 15
      }
    },

    getCurrentCenter() {
      if(this.unit) {
        if(this.unit.position) {
          const positionArray = (this.positions[0].latitude + ', ' + this.positions[0].longitude).split(', ').map(Number)
          return positionArray
        }
        else {
          return [50.595414, 36.587277]
        }
      }
      else {
        return localStorage[centerMapKey] ? JSON.parse(localStorage[centerMapKey]) : [50.595414, 36.587277]
      }
    },
  }

}
</script>

<style lang="sass">
@import "src/styles/quasar.variables.sass"
@import "~leaflet.markercluster/dist/MarkerCluster.css"
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css"

.m-custom-control
  position: absolute
  z-index: 1500
  top: 0
  right: 0
  background-color: rgba(245, 245, 245, 0.5)
  margin-right: 10px
  margin-top: 10px
  border: 1px solid lightgray

.m-custom-active-class
  color: white
  background: $primary

.m-custom-tooltip
  color: red
  font-weight: bold
  font-size: 16px
  background-color: transparent
  border: none
  box-shadow: none

</style>
