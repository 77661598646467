import { render, staticRenderFns } from "./UnitDialog.vue?vue&type=template&id=5205e04d&scoped=true&"
import script from "./UnitDialog.vue?vue&type=script&lang=js&"
export * from "./UnitDialog.vue?vue&type=script&lang=js&"
import style0 from "./UnitDialog.vue?vue&type=style&index=0&id=5205e04d&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5205e04d",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QBar,QSpace,QBtn,QLayout});
