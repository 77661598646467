import moment from 'moment'

/**
 * @param date {Date|string}
 * @return {string}
 */
function dateToString(date) {
    return moment(date).format('YY-MM-DD HH:mm:ss')
}

function dateIsValid(toValidate) {
    const timestamp = Date.parse(toValidate)
    return !Number.isNaN(timestamp)
}

/**
 * Format bytes as human-readable text.
 *
 * @param kBytes Number of kilobytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(kBytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(kBytes) < thresh) {
        return kBytes + ' KB';
    }

    const units = si
        ? ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    let u = -1;
    const r = 10**dp;

    do {
        kBytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(kBytes) * r) / r >= thresh && u < units.length - 1);


    return kBytes.toFixed(dp) + ' ' + units[u];
}

function numberWithSpaces(x) {
    const parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

function numberAround(x) {
    return Math.round(Number(x))
}

function perPageInt(x) {
    return parseInt(x)
}

function countPages(totalCount, countPerPage) {
    const pages = totalCount / countPerPage
    return Math.floor(pages) - pages === 0 ? pages : Math.floor(pages) + 1
}

async function positionToAddress(latitude, longitude) {
    const axios = require('axios')

    const result = await axios.get('https://nm3.logexpert.ru/reverse', {
        params: {
            lat: latitude,
            lon: longitude,
            zoom: 18,
            addressdetails: 1,
            format: 'json'
        }
    })

    return result.data
}

function getSrc(value) {
    return `${this.server.transport.url}/assets/${value}?access_token=${this.server.auth.token}`
}

function snapshotSrc(item) {
    if(item.image.storage === "remote") return `https://files.lvs.logexpert.ru/image/${item.image.id}`
    return `${this.server.transport.url}/assets/${item.image.id}?access_token=${this.server.auth.token}`
}

/**
 *
 * @param ms {Number}
 * @returns {Promise<void>}
 */
async function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export {
    dateToString,
    dateIsValid,
    humanFileSize,
    numberWithSpaces,
    numberAround,
    perPageInt,
    countPages,
    positionToAddress,
    getSrc,
    snapshotSrc,
    timeout
}