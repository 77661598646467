<template>
  <q-page class="custom-q-page" style="background-color: whitesmoke;">
    <q-layout view="hhh LpR ffr" container class="custom-q-layout">

<!--      <q-header class="border-for-header" style="background-color: #ffffff;">-->
<!--        <q-toolbar class="row items-center justify-between">-->
<!--          <q-btn-toggle-->
<!--              v-model="status"-->
<!--              toggle-color="primary"-->
<!--              :options="listStatuses"-->
<!--              flat-->
<!--              no-caps-->
<!--              color="black"-->
<!--              class="custom-q-btn-toggle"-->
<!--          >-->

<!--            <template v-slot:one>-->
<!--              <q-badge rounded floating color="primary">{{statuses.queue}}</q-badge>-->
<!--            </template>-->

<!--            <template v-slot:two>-->
<!--              <q-badge rounded floating color="primary">{{statuses.done}}</q-badge>-->
<!--            </template>-->

<!--            <template v-slot:three>-->
<!--              <q-badge rounded floating color="primary">{{statuses.progress}}</q-badge>-->
<!--            </template>-->

<!--            <template v-slot:four>-->
<!--              <q-badge rounded floating color="primary">{{statuses.error}}</q-badge>-->
<!--            </template>-->

<!--            <template v-slot:five>-->
<!--              <q-badge rounded floating color="primary">{{statuses.all}}</q-badge>-->
<!--            </template>-->

<!--          </q-btn-toggle>-->

<!--          <q-select dense borderless v-model="selectedSort" :options="listSorts" label="Выберите сортировку" style="width: 280px">-->

<!--            <template v-if="directionSort === 'right'" v-slot:after>-->
<!--              <q-btn dense flat icon="sort" style="transform: scaleY(-1)" @click="chooseDirectionSort()">-->
<!--                <q-tooltip>-->
<!--                  Направление сортировки-->
<!--                </q-tooltip>-->
<!--              </q-btn>-->
<!--            </template>-->

<!--            <template v-else v-slot:after>-->
<!--              <q-btn dense flat icon="sort" @click="chooseDirectionSort()">-->
<!--                <q-tooltip>-->
<!--                  Направление сортировки-->
<!--                </q-tooltip>-->
<!--              </q-btn>-->
<!--            </template>-->

<!--          </q-select>-->

<!--          <q-input-->
<!--              dense-->
<!--              v-model="search"-->
<!--              debounce="500"-->
<!--              filled-->
<!--              placeholder="Поиск"-->
<!--              style="width: 300px; background-color: #ffffff;"-->
<!--          >-->
<!--            <template v-slot:append>-->
<!--              <q-icon name="search" />-->
<!--            </template>-->
<!--          </q-input>-->
<!--        </q-toolbar>-->
<!--      </q-header>-->

<!--      <q-footer class="border-for-footer" style="background-color: #ffffff;">-->
<!--        <q-toolbar class="justify-center">-->
<!--          <q-pagination-->
<!--              v-model="currentPage"-->
<!--              :max="countPages"-->
<!--              :max-pages="5"-->
<!--              direction-links-->
<!--              :boundary-numbers="false"-->
<!--              boundary-links-->
<!--          />-->
<!--        </q-toolbar>-->
<!--      </q-footer>-->

      <q-page-container>
        <q-page>

          <div v-if="showPreload" class="row items-center justify-center" style="min-height: calc(100vh - 50px)">
            <lottie-animation
                ref="anim"
                :animationData="require('../assets/preload-animation.json')"
                :loop="true"
                :autoPlay="true"
                :speed="1"
                style="width: 200px; height: 200px"
            />
          </div>

          <div v-else class="row justify-center q-pa-md" >
<!--            <q-toolbar class="bg-primary text-white shadow-2" style="width: 80%;">-->
<!--              <q-toolbar-title>Contacts</q-toolbar-title>-->
<!--            </q-toolbar>-->

            <q-list v-if="readPermissions" bordered separator style="width: 80%; background-color: white">
              <q-item class="bg-primary">
                <q-item-section v-for="label in listLabels(readPermissions, fieldsForRead)" :key="label" style="text-align: center; color: white">
                  {{label}}
                </q-item-section>
              </q-item>
              <q-scroll-area class="custom-scroll-area" style="height: calc(100vh - 150px)">
                <q-item v-for="item in items" :key="item.id" ref="itemsForRead">
                  <q-item-section v-for="(value, name) in deleteUserKeys(item)"
                                  :key="name"
                                  :data-content="name"
                                  style="text-align: center">
                    {{value ? formatterValue(value, name) : '-'}}
                  </q-item-section>
                  <q-item-section v-if="updatePermissions || deletePermissions" style="text-align: center">
                    <div>
                      <q-btn v-if="updatePermissions" color="primary" round dense flat icon="edit" @click="currentAction = 'update'; executeCurrentItem($event)"/>
                      <q-btn v-if="deletePermissions" color="red" round dense flat icon="delete" @click="currentAction = 'delete'; executeCurrentItem($event)"/>
                    </div>
                  </q-item-section>
                </q-item>
              </q-scroll-area>
            </q-list>
          </div>

          <q-dialog v-model="openModalWindow">

            <q-card style="width: 70%">

              <q-card-section v-if="currentAction !== 'delete'">
                <div v-if="currentAction === 'create'" class="text-h6">Создание устройства</div>
                <div v-if="currentAction === 'update'" class="text-h6">Редактирование устройства</div>
              </q-card-section>

              <q-card-section v-if="currentAction !== 'delete'">
                <q-form style="text-align: right" @submit.prevent="chooseAction()">
                  <template v-for="item in chooseFieldsCollection">
                  <q-input
                           v-if="checkInterface(item)"
                           :key="item"
                           v-model.trim="modelsForForm[item]"
                           :label="getRuNameFields(item)"
                           :type="getType(item)"
                           :rules="getRules(item)"
                            style="padding-bottom: 20px"/>
                    <q-select
                        v-else
                        :key="item"
                        class="col-8"
                        v-model="modelsForForm[item]"
                        clearable
                        input-debounce="0"
                        :label="getRuNameFields(item)"
                        :rules="getRules(item)"
                        :options="getOptions(item)"
                    >
                      <template v-slot:no-option>
                        <q-item>
                          <q-item-section class="text-grey">
                            Никаких результатов
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </template>
                  <q-btn flat label="OK" color="primary" style="display: inline-block" type="submit"/>
                </q-form>
<!--                <q-form v-if="currentAction === 'create'" style="text-align: right" @submit="create">-->
<!--                  <q-input v-for="(item, index) in fieldsForCreate"-->
<!--                           :key="index"-->
<!--                           v-model.trim="modelsForForm[item]"-->
<!--                           :label="getRuNameFields(item)"-->
<!--                           :rules="[ val => val && val.trim().length > 0 || 'Пожалуйста введите необходимое значение!']" />-->
<!--                  <q-btn flat label="OK" color="primary" style="display: inline-block" type="submit"/>-->
<!--                </q-form>-->
<!--                <q-form v-if="currentAction === 'update'" style="text-align: right" @submit="update">-->
<!--                  <q-input v-for="(item, index) in fieldsForUpdate"-->
<!--                           :key="index"-->
<!--                           v-model.trim="modelsForForm[item]"-->
<!--                           :label="getRuNameFields(item)"-->
<!--                           :rules="[ val => val && val.trim().length > 0 || 'Пожалуйста введите необходимое значение!']" />-->
<!--                  <q-btn flat label="OK" color="primary" style="display: inline-block" type="submit"/>-->
<!--                </q-form>-->
              </q-card-section>

              <template v-if="currentAction === 'delete'">
                <q-card-section style="font-size: 18px">Вы действительно уверены, что хотите удалить устройство?</q-card-section>
                <q-card-actions class="justify-end">
                  <q-btn flat color="primary" label="Отмена" @click="openModalWindow = false"/>
                  <q-btn flat color="primary" label="OK" @click="deleteDevice"/>
                </q-card-actions>
              </template>

            </q-card>

          </q-dialog>

          <q-page-sticky v-if="createPermissions" position="bottom-right" :offset="[18, 18]">
            <q-btn fab icon="add" color="secondary" @click="currentAction = 'create'; openModalWindow = true"/>
          </q-page-sticky>

        </q-page>
      </q-page-container>
    </q-layout>
  </q-page>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
// import {humanFileSize, perPageInt, countPages} from '../utils';
import LottieAnimation from 'lottie-web-vue';
import moment from "moment";

const selectedSortKey = 'devices_selectedSort';
const directionSortKey = 'devices_directionSort';
// const searchKey = 'tasks_search';


export default {
  name: 'Devices',
  props: {

  },
  components: {
    LottieAnimation
  },
  data () {
    return {
      items: [],
      showPreload: true,
      openModalWindow: false,
      updatePermissions: null,
      readPermissions: null,
      createPermissions: null,
      deletePermissions: null,
      fieldsForUpdate: [],
      fieldsForRead: [],
      fieldsForCreate: [],
      fields: [],
      modelsForForm: {
          id: '',
          ident: '',
          sim: '',
          codec: '',
          timezone_offset: '',
          type: '',
        },
      currentAction: '',
      // currentField: '',



      // videoShow: '',
      // search: '',
      // currentPage: 1,
      // perPage: '12',
      // count: 0,
      // status: null,
      // listStatuses: [
      //   {label: `в очереди`, value: 'queue', slot: 'one'},
      //   {label: 'завершено', value: 'done', slot: 'two'},
      //   {label: 'выполняется', value: 'progress', slot: 'three'},
      //   {label: 'ошибка', value: 'error', slot: 'four'},
      //   {label: 'все', value: null, slot: 'five'}
      // ],
      // statuses: {
      //   all: 0,
      //   queue: 0,
      //   progress: 0,
      //   done: 0,
      //   error: 0
      // },
      // selectedSort: localStorage[selectedSortKey] ? JSON.parse(localStorage[selectedSortKey]) : {label: 'по дате создания', value: 'date_created'},
      // listSorts: [
      //   {label: 'по дате создания', value: 'date_created'},
      //   {label: 'по дате интервала', value: 'begin'}
      // ],
      // directionSort: localStorage[directionSortKey] ?? 'right',
    }
  },

  watch: {
    openModalWindow(n) {
      if(!n) {
        for (let key in this.modelsForForm) {
          this.modelsForForm[key] = ''
        }
      }
    }
  },

  async mounted() {
    this.setTitle('Устройства')

    await this.fetchFields()

    this.updatePermissions = this.devicePermissions.find(item => item.action === 'update')
    if (this.updatePermissions) {
      this.fieldsForUpdate = this.updatePermissions.fields.slice()
    }

    this.readPermissions = this.devicePermissions.find(item => item.action === 'read')
    if (this.readPermissions) {
      this.fieldsForRead = this.readPermissions.fields.slice()
    }

    this.createPermissions = this.devicePermissions.find(item => item.action === 'create')
    if (this.createPermissions) {
      this.fieldsForCreate = this.createPermissions.fields.slice()
    }

    this.deletePermissions = this.devicePermissions.find(item => item.action === 'delete')

    console.debug(this.fieldsForRead)
    console.debug(this.devicePermissions)

    await this.fetchDevices()

    this.showPreload = false

  },

  methods: {
    ...mapActions({
      'setTitle': 'layout/setToolbarTitle',
      // 'setPermissions': 'server/setPermissions'
    }),

    async fetchFields() {
      const result = await this.server.fields.readMany('device')
      this.fields = result
    },

    async fetchDevices() {
      let result = null

      try {
        result = await this.server.items('device').readMany({
          limit: -1,
          // sort: '-date_created',
          fields: this.fieldsForRead
        })
      }
      catch (e) {
        console.debug('fetching error', e)
        this.$q.notify({
          message: `Ошибка получения объектов: ${e.message}`
        })
      }

      if(result) {
        this.items = result.data
      }
      console.debug(this.items)
    },

    formatterValue(value, name) {
      if (name === 'date_created' || name === 'date_updated') {
        return this.getTimestamp(value)
      }
      if (name === 'unit') {
        return value[0] ? value[0] : '-'
      }
      else {
        return value
      }
    },

    getTimestamp(value) {
      // return moment(value).format('YYYY-MM-DD HH:mm:ss')
      return moment(value).format('LLL')
    },

    deleteUserKeys(item) {
      const newObject = {}
      Object.assign(newObject, item)
      delete newObject['user_created']
      delete newObject['user_updated']
      delete newObject['account']
      return newObject
    },

    listLabels(permissions, fields) {
      if (permissions) {
        const newArrayWithoutUser = fields.filter(item => item !== 'user_created' && item !== 'user_updated' && item !== 'account').map(item => {
          return this.getRuNameFields(item)
        })
        if(this.updatePermissions || this.deletePermissions) newArrayWithoutUser.push('Действия')

        return newArrayWithoutUser
      }
      else return []
    },

    getRuNameFields(item) {
      if(item === 'id') return 'id устройства'
      if(item === 'ident') return 'Регистратор'
      if(item === 'sim') return 'Номер сим-карты'
      if(item === 'date_updated') return 'Дата обновления'
      if(item === 'date_created') return 'Дата создания'
      if(item === 'type') return 'Тип регистратора'
      if(item === 'codec') return 'Codec'
      if(item === 'timezone_offset') return 'Смещение времени'
      if(item === 'unit') return 'id объекта'
      else return item
    },

    async create() {
      const ident = this.modelsForForm.ident ? this.modelsForForm.ident : null
      const sim = this.modelsForForm.sim ? this.modelsForForm.sim : null
      const codec = this.modelsForForm.codec ? this.modelsForForm.codec : null
      const timezone_offset = this.modelsForForm.timezone_offset ? Number(this.modelsForForm.timezone_offset) : null
      const type = this.modelsForForm.type ? this.modelsForForm.type : null

      const value = {
        ident, sim, codec, timezone_offset, type
      }

      let result;

      try {
        result = await this.server.items('device').createOne(value)
        console.debug('creation result', result)

        this.$q.notify({
          message: 'Устройство успешно создано!',
          color: 'primary'
        })
      }
      catch (e) {
        console.debug('creation error', e)
        this.$q.notify({
          message: `Ошибка создания: ${e.message}`,
          color: 'red'
        })
      }

      this.items.push(result)

      this.openModalWindow = false
    },

    async update() {
      const value = {}

      this.fieldsForUpdate.forEach(item => {
        if (item === 'timezone_offset') {
          value[item] = Number(this.modelsForForm[item])
        }
        else {
          value[item] = this.modelsForForm[item] ? this.modelsForForm[item] : null
        }
      })

      let result;

      try {
        result = await this.server.items('device').updateOne(this.modelsForForm.id, value)
        console.debug('update result', result)

        this.$q.notify({
          message: 'Успешно сохранено!',
          color: 'primary'
        })
      }

      catch (e) {
        console.debug('fetching error', e)
        this.$q.notify({
          message: `Ошибка обновления устройства: ${e.message}`
        })
      }

      const currentIndex = this.items.findIndex(item => item.id === this.modelsForForm.id)
      this.items.splice(currentIndex, 1, result)

      this.openModalWindow = false
    },

    async deleteDevice() {
      try {
        await this.server.items('device').deleteOne(this.modelsForForm.id)
        console.debug('delete result')

        this.$q.notify({
          message: 'Успешно удалено!',
          color: 'primary'
        })
      }

      catch (e) {
        console.debug('fetching error', e)
        this.$q.notify({
          message: `Ошибка удаления устройства: ${e.message}`
        })
      }

      const currentIndex = this.items.findIndex(item => item.id === this.modelsForForm.id)
      this.items.splice(currentIndex, 1)

      this.openModalWindow = false
    },

    executeCurrentItem(e) {
      this.openModalWindow = true;

      const currentItem = e.target.closest('.q-item')
      if (currentItem) {
        const currentId = currentItem.querySelector(`[data-content='id']`).textContent.trim()
        this.modelsForForm.id = Number(currentId)
      }

      if(this.currentAction === 'update') {
        console.debug('edit')

        this.fieldsForUpdate.forEach(item => {
          for (let key in this.modelsForForm) {
            if (key === item) {
              const currentSectionText = currentItem.querySelector(`[data-content=${key}]`).textContent.trim()
              console.debug(currentSectionText)
              if(currentSectionText === '-') {
                this.modelsForForm[key] = ''
              }
              else {
                  this.modelsForForm[key] = currentSectionText
              }
            }
          }
        })

      }
      // if(this.currentAction === 'create') {
      //   console.debug('create')
      // }
      // if(this.currentAction === 'delete') {
      //   console.debug('delete')
      // }
    },

    chooseAction() {
      if (this.currentAction === 'create') {
        this.create()
      }
      else {
        this.update()
      }
    },

    getType(value) {
      const currentField = this.fields.find(item => item.field === value) //вынести в цикл!!!!
      if(currentField.type === 'string') {
        return 'text'
      }
      else {
        return 'number'
      }
    },

    getRules(value) {
      const currentField = this.fields.find(item => item.field === value)
      const rules = []
      if(!currentField.schema.is_nullable) {
        // console.debug('requier')
        rules.push(val => val && val.trim().length > 0 || 'Пожалуйста заполните обязательное поле!')
      }
      if(currentField.schema.max_length) {
        // console.debug('max_length')
        if (value) {
          rules.push(val => val.trim().length <= currentField.schema.max_length  || `Значение должно содержать не более ${currentField.schema.max_length} символов!`)
        }
      }
      return rules
    },

    getOptions(value) {
      const currentField = this.fields.find(item => item.field === value)
      const options = []
      currentField.meta.options.choices.forEach(item => {
        // const option = {
        //   label: item.text,
        //   value: item.value
        // }
        // options.push(option)
        options.push(item.text)
      })
      return options
    },

    checkInterface(value) {
      const currentField = this.fields.find(item => item.field === value)
      if (currentField.meta.interface !== 'select-dropdown') {
        return true
      }
      else return false

      // const selectsInterface = this.fields.filter(item => item.meta.interface === 'select-dropdown')
      // selectsInterface.forEach(item => {
      //   console.debug(item.field)
      // })
      // return value !== 'codec' && value !== 'type'
    }
  },

  computed: {
    ...mapGetters({
      'server': "server/instance",
      'devicePermissions': 'server/devicePermissions'
    }),

    chooseFieldsCollection() {
      if (this.currentAction === 'create') {
        return this.fieldsForCreate
      }
      else {
        return this.fieldsForUpdate
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/styles/quasar.variables.sass"

.custom-q-layout
  min-height: calc(100vh - 50px)

.tree-parent > .q-tree__node
  border: 1px solid #eceff1

.tree-parent * .q-tree__node-header
  margin-top: 0

.custom-load-task > .q-item__section
  margin-bottom: 10px

.padding
  margin: 1%

//.custom-scroll-area * .q-item
//  border: 1px solid rgba(0, 0, 0, 0.12)    // для разделителей в таблице
</style>
