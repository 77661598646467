const state = () => ({
    toolbarTitle: null
})

const getters = {
    toolbarTitle: state => {
        return state.toolbarTitle
    }
}

const mutations = {
    setToolbarTitle(state, value) {
        state.toolbarTitle = value
    }
}

const actions = {
    setToolbarTitle({commit}, value) {
        commit('setToolbarTitle', value);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
