<template>
  <q-dialog
      autofocus
      v-model="openUnit"
      persistent
      :maximized="maximizedToggle"
      transition-show="slide-up"
      transition-hide="slide-down"
      @keydown.esc="closeUnitDialog()"
  >
    <q-card>
      <q-bar class="bg-primary">
        <div style="color: white">{{nameObject}}</div>
        <q-space />
        <q-btn flat icon="close" color="white" @click="closeUnitDialog()"/>
      </q-bar>
      <!--      <q-card style="position: relative; max-width: none; height: 100vh; width: 100%">-->

      <q-layout view="hhh LpR ffr" style="min-height: calc(100vh - 150px)">
        <unit :id="id" @updated="fetch"/>
      </q-layout>

    </q-card>
  </q-dialog>
</template>

<script>
import {mapGetters} from "vuex"
import Unit from "../views/Unit"

export default {
  name: "UnitDialog",
  components: {Unit},
  props: {
    // unit: {
    //   required: true,
    //   type: Object
    // },
    id: {
      required: false,
      type: Number
    },
    // nameObject: {
    //   required: false,
    //   type: String
    // },
    openUnit: {
      required: true,
      type: Boolean
    },
  },

  data() {
    return {
      nameObject: '',
      maximizedToggle: true,
    }
  },

  async mounted() {
    // await this.fetch()
  },

  watch: {
    async openUnit(n) {
      if(n) {
        await this.fetch()
      }
    }
  },

  computed: {
    ...mapGetters({
      'server': "server/instance",
    }),

    // token() {
    //   return this.server.auth.token
    // }
  },

  methods: {
    // getSrc(value) {
    //   return `${this.server.transport.url}/assets/${value}?access_token=${this.token}`
    // },
    async fetch() {
      try {
        this.nameObject = (await this.server.items('unit').readOne(this.id, {
          // fields: ['id', 'name', 'device.ident', 'device.type', 'device.id']
          fields: ['id', 'name']
        })).name
        console.debug(this.nameObject)
      }
      catch (e) {
        console.debug('fetching error', e)
        this.$q.notify({
          message: `Ошибка получения объекта: ${e.message}`
        })
      }
    },

    closeUnitDialog() {
      console.debug('close-unit-dialog')
      this.$emit('close-unit-dialog')
    },
  },
}
</script>

<style lang="sass" scoped>
@import "src/styles/quasar.variables.sass"


</style>