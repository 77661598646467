import { Directus, LocalStorage, Auth, AxiosTransport } from "@directus/sdk";

const url = 'https://lvs-cms.logexpert.ru'

/**
 * @type {Directus}
 */
let instance

const state = () => ({
    /**
     * @type { Object[] }
     */
    permissions: null
})

const getters = {
    /**
     *
     * @return {Directus}
     */
    instance: () => {
        if(!instance) {
            const storage = new LocalStorage()

            const transport = new AxiosTransport(url, storage, async () => {
                await auth.refresh(); // This is how axios checks for refresh
            })

            const auth = new Auth(transport, storage, {
                mode: 'json',
            })

            instance = new Directus(url, {
                auth,
                storage,
                transport,
            })

            // instance = new Directus(url)
        }

        return instance
    },

    authenticated: () => {
        return !!instance?.auth?.token
    },

    permissions: state => {
        return state.permissions
    },

    devicePermissions: getters => {
        return getters.permissions?.filter(x => x.collection === 'device')
    },

    unitPermissions: getters => {
        return getters.permissions?.filter(x => x.collection === 'unit')
    }
}

const actions = {
    setPermissions({commit}, value) {
        commit('setPermissions', value)
    }
}

const mutations = {
    setPermissions(state, value) {
        state.permissions = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
