import { render, staticRenderFns } from "./LoadTaskPreview.vue?vue&type=template&id=5be3a3d6&scoped=true&"
import script from "./LoadTaskPreview.vue?vue&type=script&lang=js&"
export * from "./LoadTaskPreview.vue?vue&type=script&lang=js&"
import style0 from "./LoadTaskPreview.vue?vue&type=style&index=0&id=5be3a3d6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5be3a3d6",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QDialog,QCard,QBtn,QSeparator,QChip,QAvatar,QTooltip,QForm,QInput,QCardSection});
