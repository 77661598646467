<template>
  <q-dialog v-model="openSnapshotDialog">
    <q-card style="width: 50% !important; max-width: 50% !important">
      <img :src="snapshotSrc(image)"/>
<!--      <q-btn fab icon="close" style="position: absolute; right: 30px; bottom: 30px;  z-index: 1500" color="primary" @click="closeSnapshotDialog()"/>-->
    </q-card>
  </q-dialog>
</template>

<script>
import {mapGetters} from "vuex"
import {getSrc, snapshotSrc} from "../utils";


export default {
  name: "SnapshotDialog",
  props: {
    image: {
      required: true,
      type: Object
    },
    openSnapshot: {
      required: true,
      type: Boolean
    },
  },

  data() {
    return {
      openSnapshotDialog: this.openSnapshot
    }
  },
  mounted() {
    // const backdrop = document.querySelector('.q-dialog-backdrop')
    // if (backdrop) {
    //   backdrop.addEventListener('click', () => {
    //     this.closeSnapshotDialog()
    //   })
    // }
  },

  watch: {
    openSnapshot() {
      // this.closeSnapshotDialog()
      this.openSnapshotDialog = this.openSnapshot

    },
    openSnapshotDialog(n) {
      if (!n) {
        this.closeSnapshotDialog()
      }
    }
  },

  computed: {
    ...mapGetters({
      'server': "server/instance",
    }),
    // token() {
    //   return this.server.auth.token
    // }
  },

  methods: {
    // getSrc,

    snapshotSrc,


    // getSrc(value) {
    //   return `${this.server.transport.url}/assets/${value}`
    // },
    // getSrc(value) {
    //   return `${this.server.transport.url}/assets/${value}?access_token=${this.server.auth.token}`
    // },
    closeSnapshotDialog() {
      console.debug('close-snapshot-dialog')
      this.$emit('close-snapshot-dialog')
    },
  },
}
</script>

<style lang="sass" scoped>
@import "src/styles/quasar.variables.sass"


</style>