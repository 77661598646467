<template>
    <q-card style="min-width: 50%" bordered>
      <q-card-section>
        <div class="text-h6">Создание задания</div>
      </q-card-section>
      <q-card-section>
        <q-form>
          <q-input label="дата" filled v-model="createData.date" mask="date" :rules="['date', getDatesRange]">
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                  <q-date v-model="createData.date" :options="getDatesRange">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Закрыть" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
          <q-card-section>
            <div class="row justify-evenly">
<!--              <el-time-picker-->
<!--                  is-range-->
<!--                  v-model="createData.time"-->
<!--                  range-separator="До"-->
<!--                  start-placeholder="Начало"-->
<!--                  end-placeholder="Конец"-->
<!--                  class="justify-between"-->
<!--                  style="width: 70%"-->
<!--                  @input="showDate"-->
<!--              >-->
<!--              </el-time-picker>-->
<!--              <label style="cursor: pointer">-->
<!--                <div>Начало: </div>-->
<!--              <input type="time" name="beginTime" v-model="createData.beginTime" style="cursor: pointer" @input="showDate"/>-->
                  <q-input :rules="[ val => createData.fullBeginDate < createData.fullEndDate || 'Начало не должно быть позже конца']" filled type="time" name="beginTime" v-model="createData.beginTime" style="cursor: pointer" @input="setDate()" hint="Начало" />
<!--              </label>-->
<!--              <label style="cursor: pointer">-->
<!--                <span>Конец: </span>-->
<!--            <input type="time" name="endTime" v-model="createData.endTime" style="cursor: pointer" />-->
                <q-input :rules="[ val => createData.fullEndDate > createData.fullBeginDate || 'Конец не должен быть раньше начало']" filled type="time" name="endTime" v-model="createData.endTime" style="cursor: pointer" @input="setDate()" hint="Конец" />
<!--              </label>-->
            </div>
            <div class="q-pa-md row justify-center">
            <q-chip id="5m" clickable @click="chooseInterval($event)" color="primary" text-color="white" icon="alarm">
              За последние 5 минут
            </q-chip>
              <q-chip id="15m" clickable @click="chooseInterval($event)" color="primary" text-color="white" icon="alarm">
                За последние 15 минут
              </q-chip>
            </div>
          </q-card-section>
          <q-card-section>
            <div class="row justify-evenly">
              <q-select
                  :disable="unit ? true : false"
                  class="col-8"
                  filled
                  v-model="selectedUnit"
                  use-input
                  clearable
                  hide-selected
                  fill-input
                  input-debounce="0"
                  label="Выберите объект"
                  :options="units"
                  @filter="unitSelectFn"
                  @filter-abort="()=>{}"
                  style="width: 250px"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Никаких результатов
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>

              <q-select
                  :disable="selectedUnit ? false : true"
                  class="col-8"
                  filled
                  clearable
                  v-model="camera"
                  label="Выберите камеру"
                  :options="cameras"
                  @filter="cameraSelectFn"
                  @filter-abort="()=>{}"
                  style="width: 250px"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Никаких результатов
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
          </q-card-section>
          <q-input v-model="createData.description" label="описание" filled/>
        </q-form>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="OK" color="primary" @click="create" :disable="canCreate"/>
      </q-card-actions>
    </q-card>
</template>

<script>
import moment from "moment";
import {dateIsValid} from "../utils";
import {mapGetters} from "vuex";

export default {
  name: "LoadTaskCreateForm",
  // props: ['id'],

  props: {
    id: {
      type: Number,
      required: true
    },

    date: {
      type: String
    },

    beginTime: {
      type: String,
    },

    endTime: {
      type: String,
    }
  },

  data() {
    return {
      createData: {
        beginTime: '00:00',
        endTime: '23:59',
        fullBeginDate: '',
        fullEndDate: '',
      },
      // creatingProcess: false,
      unit: null,
      units: null,
      selectedUnit: null,
      cameras: null,
      camera: null
    }
  },

  async mounted() {
    // console.debug(this.createData.beginTime)
    // console.debug(this.createData.endTime)
    await this.fetch()
    this.resetCreationData()
    this.initTimePickerValue()

    if(this.unit) this.selectedUnit = { label: this.unit.name, value: this.unit.id }
  },

  methods: {
    async fetch() {
      if (this.id) {
        this.unit = await this.server.items('unit').readOne(this.id, {
          fields: ['id', 'name']
        })
      }

    },
    chooseInterval(e) {
      const today = new Date();
      // console.debug(moment(today).format('mm'));

      // this.createData.endTime = today.getHours() + ':' + today.getMinutes();
      this.createData.endTime = moment(today).format('HH:mm');
      // console.debug(today);
      // console.debug(e.currentTarget.id);

      if (e.currentTarget.id === '5m') {
        const fiveMinutesAgoDate = new Date( Date.now() - 1000 * (60 * 5) );
        // const fiveMinutesAgoValue = fiveMinutesAgoDate.getHours() + ':' + fiveMinutesAgoDate.getMinutes();
        const fiveMinutesAgoValue = moment(fiveMinutesAgoDate).format('HH:mm');
        this.createData.beginTime = fiveMinutesAgoValue;
      }
      else {
        const fifteenMinutesAgoDate = new Date( Date.now() - 1000 * (60 * 15) );
        // const fifteenMinutesAgoValue = fifteenMinutesAgoDate.getHours() + ':' + fifteenMinutesAgoDate.getMinutes();
        const fifteenMinutesAgoValue = moment(fifteenMinutesAgoDate).format('HH:mm');
        this.createData.beginTime = fifteenMinutesAgoValue;
      }
      
      this.setDate();


      // console.debug(this.createData.beginTime);
      // console.debug(fifteenMinutesAgoValue);
      // console.debug(fiveMinutesAgo.getMinutes());
      // console.debug(fifteenMinutesAgo);
      // console.debug(new Date().getMinutes());
      // console.debug(new Date().getHours());

    },

    setDate() {

      // let fileds = this.createData.beginTime.split(':');
      // console.debug(fileds);
      const data = this.createData
      const partsBeginTime = data.beginTime.split(':');
      const partsEndTime = data.endTime.split(':');
      const createDate = new Date();
      const rightBeginTime = new Date(createDate.setHours(parseInt(partsBeginTime[0]), parseInt(partsBeginTime[1]), 0));
      const rightEndTime = new Date(createDate.setHours(parseInt(partsEndTime[0]), parseInt(partsEndTime[1]), 0));
      console.debug(rightBeginTime);
      // console.debug(rightEndTime);

      data.fullBeginDate = rightBeginTime;
      data.fullEndDate = rightEndTime;
    },

    async create() {
      // this.creatingProcess = true

      const data = this.createData
      const date = data.date
      const partsBeginTime = data.beginTime.split(':');
      const partsEndTime = data.endTime.split(':');
      // const numberPartsBeginTime1 = Number(partsBeginTime[0]);
      // const numberPartsBeginTime2 = Number(partsBeginTime[1]);
      // const numberPartsBeginTime = parseInt(partsBeginTime.[0]);
      // const beginWithSeconds = data.beginTime + ':00'
      // const endWithSeconds = data.endTime + ':00'
      // const beginTime = moment(beginWithSeconds).format('HH:mm:ss')
      // const endTime = moment(endWithSeconds).format('HH:mm:ss')


      // const beginTime = moment(data.time[0]).format('HH:mm:ss')
      // const endTime = moment(data.time[1]).format('HH:mm:ss')

      // const beginTime = moment(data.begin).format('HH:mm:ss')
      // const endTime = moment(data.endTime).format('HH:mm:ss')

      const createDate = new Date();
      const rightBeginTime = new Date(createDate.setHours(parseInt(partsBeginTime[0]), parseInt(partsBeginTime[1]), 0));
      const rightEndTime = new Date(createDate.setHours(parseInt(partsEndTime[0]), parseInt(partsEndTime[1]), 0));
      console.debug(rightBeginTime);

      data.fullBeginDate = rightBeginTime;
      data.fullEndDate = rightEndTime;

      const beginTime = moment(rightBeginTime).format('HH:mm:ss')
      const endTime = moment(rightEndTime).format('HH:mm:ss')

      const begin = moment(`${date} ${beginTime}`).toDate()
      const end = moment(`${date} ${endTime}`).toDate()

      console.debug('creation times:', {
        data, beginTime, endTime,
        begin, end
      })

      const camera = this.camera.value
      const description = this.createData.description

      const value = {
        begin, end, camera, description
      }

      console.debug('creation data', value)
      try {
        const result = await this.server.items('load_video_task').createOne(value)
        console.debug('creation result', result)

        if(!this.unit) {
          this.selectedUnit = null;
        }

        this.resetCreationData()

        this.$q.notify({
          message: 'Задание успешно создано',
          color: 'primary'
        })
      }
      catch (e) {
        console.debug('creation error', e)
        this.$q.notify({
          message: `Ошибка создания: ${e.message}`,
          color: 'red'
        })
      }

      // this.creating = false
      // this.creatingProcess = false
      this.$emit('created')
    },

    // eslint-disable-next-line no-unused-vars
    async unitSelectFn(val, update, abort) {

        const result = await this.server.items('unit').readMany({
          limit: -1,
          fields: ['id', 'name']
        })

        console.debug('result of items:', result)

        const prepared = result.data.map(x => {
          return {
            label: x.name,
            value: x.id
          }
        })

        console.debug(prepared)

        update(() => {
             const needle = val.toLowerCase()
             this.units = prepared.filter(item => item.label.toLowerCase().includes(needle))
          })

    },

    // eslint-disable-next-line no-unused-vars
    async cameraSelectFn(val, update, abort) {
      if(this.cameras?.length > 0) {
        update()
      }
      else {
        const filter = {
          unit: {
            id: {
              _eq: this.selectedUnit.value
            }
          }
        }

        console.debug('cameras filter', filter)

        update()

        const result = await this.server.items('camera').readMany({
          filter: filter,
          limit: -1,
          sort: ['-channel'],
          fields: ['id', 'name', 'channel']
        })

        const prepared = result.data.map(x => {
          return {
            label: x.name ? `${x.name} (${x.channel})` : `канал: ${x.channel}`,
            value: x.id
          }
        })

        update(() => {
          this.cameras = prepared
        })
      }
    },

    resetCreationData() {
      this.createData = {
        beginTime: this.beginTime || '00:00',
        endTime: this.endTime || '23:59',
        date: this.date || moment().format('YYYY/MM/DD')
      }
    },

    getDatesRange(date) {
      const now = moment().format('YYYY/MM/DD')
      console.debug('now:', now)
      return date <= now
    },

    initTimePickerValue() {
      const date = this.createData.date.replace(/\//g, '-')

      const begin = new Date(`${date}T${this.createData.beginTime}:00`);
      const end = new Date(`${date}T${this.createData.endTime}:00`);

      this.createData.fullBeginDate = begin;
      this.createData.fullEndDate = end;

    },
  },

  computed: {
    ...mapGetters({
      'server': 'server/instance'
    }),

    canCreate() {
      const data = this.createData

      // return !(data.date && this.camera && dateIsValid(data.time[0]) && dateIsValid(data.time[1]))
      // return !(data.date && this.camera && dateIsValid(data.beginTime) && dateIsValid(data.endTime))
      // return console.debug(!(data.date && this.camera && dateIsValid(data.fullBeginDate) && dateIsValid(data.fullEndDate) && this.fullBeginDate > this.fullEndDate))
      // console.debug(new Date(this.fullBeginDate));

      // console.debug(data.fullEndDate);
      // console.debug(data.fullBeginDate < data.fullEndDate)
      return !(this.selectedUnit && data.date && this.camera && dateIsValid(data.fullBeginDate) && dateIsValid(data.fullEndDate) && data.fullBeginDate < data.fullEndDate)
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    unit: function (val) {
      this.camera = null
      this.cameras = null
    },

    selectedUnit(n) {
      if (!n) {
        this.camera = null
        this.cameras = null
      }
    }
  }
}
</script>

<style scoped>
/*.error > .q-field__inner > .q-field__control {*/
/*  color: red !important;*/
/*}*/
/*.error > .q-field__control {*/
/*  color: red !important;*/
/*}*/
/*.error * .q-field__control::before {*/
/*   border-bottom: 1px solid red !important;*/
/*}*/
</style>