<template>
  <q-card style="height: 100%; display: flex; flex-direction: column">
    <slot name="widget" :update="update" :data="$data" :props="$props" :apexChartData="radialBarLimitData" :apexChartOptions="radialBarLimitOptions"></slot>
  </q-card>
</template>

<script>
import {mapGetters} from "vuex";
import {widget} from "./mixins/widget";

let countFormatter;
let limitFormatter;

export default {
  name: "DevicesLimitWidget",
  mixins: [widget],

  data() {
    return {
      // name: 'Транспорт',
      count: 0,
      limit: 0,
    }
  },

  async mounted() {
    // await this.fetchLimit()
    // await this.fetchCount()
    // limitFormatter = this.limit
    // countFormatter = this.count


    // await this.fetchOnline()

    // setTimeout(() => this.loaded = true, 300)
  },

  methods: {
    // checkoutVisibility() {
    //   if(!this.updatedProps) return
    //   this.updatedProps = false
    //   setTimeout(() => this.updatedProps = true, 300)
    // },

    async fetch() {
      await this.fetchLimit()
      await this.fetchCount()
      limitFormatter = this.limit
      countFormatter = this.count
    },

    async fetchLimit() {
      try {
        const result = await this.server.items('limit').readMany({
          fields: ['devices']
        })

        this.limit = result.data[0].devices
      }
      catch (e) {
        console.error('fetch limit units error', e)
      }
    },

    async fetchCount() {
      let result = null

      try {
        result = await this.server.items('device').readMany({
          limit: 0,
          meta: 'total_count'
        })
      }
      catch (e) {
        console.debug('fetching error', e)
        this.$q.notify({
          message: `Ошибка получения объектов: ${e.message}`
        })
      }

      if(result) this.count = result.meta.total_count
      console.debug(result)
    },

    // async fetchOnline() {
    //   const from = new Date( Date.now() - 1200 * 1000 )
    //
    //   let result = null
    //
    //   try {
    //     result = await this.server.items('unit_position').readMany({
    //       limit: 0,
    //       meta: 'filter_count',
    //       filter: {
    //         date_updated: {
    //           _gte: from.toISOString()
    //         }
    //       }
    //     })
    //   }
    //   catch (e) {
    //     console.debug('fetching error', e)
    //     this.$q.notify({
    //       message: `Ошибка получения онлайн: ${e.message}`
    //     })
    //   }
    //
    //   if(result) this.online = result.meta.filter_count
    //   console.debug(result)
    // },

  },

  computed: {
    ...mapGetters({
      'server': "server/instance"
    }),

    // offline() {
    //   return this.count - this.online
    // },

    radialBarLimitData() {
      return [(this.count*100)/this.limit]
    },

    radialBarLimitOptions() {
      return {
        chart: {
          type: 'radialBar',
        },
        labels: ['Устройства'],
        // theme: {
        //   palette: this.chartsPallet,
        // },
        colors: this.chartsColors,
        plotOptions: {
          radialBar: {
            labels: {
              show: true
            },
            // track: {
            //   background: '#FD6A6A',
            // },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: true,
                offsetY: 3,
                formatter: function (val) {
                  if(!limitFormatter) {
                    return 0 + '%'
                  }
                  else {
                    return val + '%'
                  }
                }
              },

            }
          }
        },
        // tooltip: {
        //   y: {
        //     formatter: function(value) {
        //       console.debug(value)
        //       return value.toFixed(1) + ' %'
        //     },
        //     title: {
        //       formatter: (seriesName) => seriesName + ':'
        //     }
        //   },
        // },

        // dataLabels: {
        //   enabled: false
        // },

        legend: {
          show: true,
          floating: false,
          fontSize: '16px',
          position: 'right',
          offsetX: 0,
          offsetY: -30,
          labels: {
            useSeriesColors: false,
          },
          markers: {
            width: 0,
            height: 0
          },
          formatter: function(seriesName) {
            if(limitFormatter) {
              return `<div style="text-align: right;">
                        <div style="font-weight: 700; font-size: 18px">${countFormatter}/${limitFormatter}</div>
                        <div class="custom-dashboard-title_small">${seriesName}</div>
                     </div>`
            }
            else {
              return `<div style="text-align: right;">
                        <div style="font-weight: 700; font-size: 18px">${countFormatter}/<i class="fas fa-infinity"></i></div>
                        <div class="custom-dashboard-title_small">${seriesName}</div>
                     </div>`
            }
          },

          itemMargin: {
            vertical: 0
          }
        },
      }
    },

    // getOnline() {
    //   const onlineArray = this.items.filter(item => {
    //     const seconds = (new Date() - moment(item.date_updated).toDate()) / 1000;
    //     return seconds < 1200;
    //   })
    //   return onlineArray.length;
    // },
    //
    // getOffline() {
    //   return this.items.length - this.getOnline;
    // }
  }
}
</script>

<style scoped>

</style>