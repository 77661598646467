<template>
  <div class="bg-white player">
    <div v-if="item.file">
<!--      <div class="row bg-grey items-center justify-center unit-name " style="height: 224px; cursor: pointer" @click="$emit('show', item.id)">-->
        <div class="row bg-grey items-center justify-center unit-name " style="height: 224px; cursor: pointer" @click="openLoadTaskDialog=true">
<!--          <q-icon v-if="!showVideo" name="play_circle_outline"  size="50px" />-->
          <q-icon name="play_circle_outline"  size="50px" />
          <q-dialog v-model="openLoadTaskDialog">
            <q-card style="display: flex; flex-direction: column; width: 50% !important; max-width: 50% !important;">
<!--              <vue-plyr>-->
<!--                <video :src="getSrc(item.id)" autoplay/>-->
<!--              </vue-plyr>-->
              <video :src="videoSrc" autoplay controls style="width: 100%;"/>
<!--              <q-btn fab icon="close" style="position: absolute; right: 30px; bottom: 60px;  z-index: 1500" color="primary" @click="openLoadTaskDialog=false"/>-->
            </q-card>
          </q-dialog>
  <!--        <div v-else>-->
  <!--          <vue-plyr>-->
  <!--            <video :src="getSrc(item.id)"/>-->
  <!--          </vue-plyr>-->
  <!--        </div>-->
      </div>
    </div>

    <div v-else class="row bg-grey items-center justify-center unit-name " style="height: 224px; position: relative" >
      <img src="../assets/no-video.png"/>
      <div style="position: absolute; top: 70%;">Нет файла</div>
    </div>

    <q-separator/>

    <div class="info-scope">
      <div class="row justify-between">
        <div class="col-5">
          <q-chip>
            <q-avatar :color="statusIconColor" text-color="white" :icon="statusIconName"/>
            {{status}}
          </q-chip>
        </div>

        <div class="col-5">
          <q-chip>
            <q-avatar color="primary" text-color="white" icon="videocam"/>
            {{item.camera.name}}
          </q-chip>
        </div>
      </div>

      <div class="unit-name col" v-if="item.camera.unit">
        {{item.camera.unit.name}}
      </div>


      <div class="row">
        <div class="col">
          {{relativeDateCreated}}
          <q-tooltip>
            {{dateCreated}}
          </q-tooltip>
        </div>
        <div class="col text-right">
          {{size}}
        </div>
      </div>

      <div class="row">
        <div class="col">
          {{interval}}
        </div>
        <div class="col-3 text-right">
          {{intervalHumanize}}
          <q-tooltip>
            длительность интервала
          </q-tooltip>
        </div>
      </div>

      <div class="row items-center" style="line-height: 40px">
        <q-btn v-if="!visibleForm" round color="primary" icon="edit" size="xs" style="margin-right: 10px" @click="showEditForm"/>
        <q-btn v-else round color="primary" icon="close" size="xs" style="margin-right: 10px" @click="hideEditForm"/>
        <div v-if="!visibleForm" class="text-bold">
        {{item.description}}
        </div>
        <q-form class="row" v-else>
          <q-input v-model="description" label="Редактировать" dense />
          <q-btn label="Сохранить" color="primary" flat class="q-ml-sm" no-caps dense @click="saveChange" />
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { dateToString, humanFileSize, getSrc } from '../utils';
import VuePlyr from "vue-plyr";
import {mapGetters} from "vuex";

export default {
  name: "LoadTaskPreview",
  components: {VuePlyr},

  props: {
    item: {
      required: true,
      type: Object
    },

    // showVideo: {
    //   type: Boolean,
    //   default: true
    // }
  },

  data() {
    return {
      visibleForm: false,
      description: this.item.description,
      openLoadTaskDialog: false
    }
  },

  methods: {
    // getSrc(value) {
    //   // return this.server.transport.url + '/assets/' + value
    //   return`${this.server.transport.url}/assets/${value}`
    // },

    toTask(id) {
      return this.item.file ? `/tasks/${id}` : null
    },

    // videoLink(value) {
    //   return this.server.transport.url + '/assets/' + value
    // },

    showEditForm() {
        this.visibleForm = true
    },

    hideEditForm() {
      this.visibleForm = false
      this.description = this.item.description
    },

    async saveChange() {
       try {
         const result = await this.server.items('load_video_task').updateOne(this.item.id, { description: this.description })

          this.$emit('updated', result)
          this.$q.notify({
            message: 'Успешно сохранено!',
            color: 'primary'
          })
        // console.debug(result)
      }

      catch (e) {
        console.debug('fetching error', e)
        this.$q.notify({
          message: `Ошибка обновления объекта: ${e.message}`
        })
      }
      console.debug(this.item)
      this.visibleForm = false
    },

  },

  computed: {
    ...mapGetters({
      'server': 'server/instance'
    }),

    videoSrc() {
      const item = this.item

      if(item.file.storage === "remote") return `https://files.lvs.logexpert.ru/video/${item.id}`

      return `${this.server.transport.url}/assets/${item.id}?access_token=${this.server.auth.token}`
    },

    relativeDateCreated() {
      // moment.duration(now.diff(then)).humanize()
      return moment.duration(moment(Date.now()).diff(this.item.date_created)).humanize() + ' назад'
    },

    dateCreated() {
      return moment(this.item.date_created).format('LLL')
    },

    begin() {
      return dateToString(this.item.begin)
    },

    interval() {
      const begin = moment(this.item.begin)
      const end = moment(this.item.end)
      // return `${begin.format('YYYY-MM-DD')} ${begin.format('HH:mm:ss')} - ${end.format('HH:mm:ss')}`
      return `${begin.format('LL')} ${begin.format('HH:mm:ss')} - ${end.format('HH:mm:ss')}`
    },

    intervalHumanize() {
      const begin = moment(this.item.begin)
      const end = moment(this.item.end)
      return moment.duration(begin.diff(end)).humanize()
    },

    end() {
      return dateToString(this.item.end)
    },

    status() {
      const value = this.item.status

      if(value === 'queue') return 'в очереди'
      else if(value === 'done') return 'завершено'
      else if(value === 'progress') return 'выполняется'
      else if(value === 'error') return 'ошибка'
      else return value
    },

    statusIconColor() {
      const value = this.item.status

      if(value === 'queue') return 'warning'
      else if(value === 'done') return 'positive'
      else if(value === 'progress') return 'info'
      else if(value === 'error') return 'negative'
      else return value
    },

    statusIconName() {
      const value = this.item.status

      if(value === 'queue') return 'query_builder'
      else if(value === 'done') return 'check'
      else if(value === 'progress') return 'cached'
      else if(value === 'error') return 'error_outline'
      else return value
    },

    camera() {
      if(this.item.camera.name) return `${this.item.camera.name} (${this.item.camera.channel})`
      else return 'канал: ' + this.item.camera.channel
    },
    groups() {
      return _.groupBy(this.items, x => x.camera.unit.name)
    },

    size() {
      return this.item.file ? humanFileSize(this.item.file.filesize / 1000, true) : null
    }
  }
}
</script>

<style scoped>
.custom-q-card-section {
  padding: 0;
}

.player {
  width: 400px;

  border: 1px solid black;
}

.unit-name {
  font-size: 18px;
}

.info-scope {
  margin: 5%;
}
</style>