<template>
      <q-card style="height: 100%; display: flex; flex-direction: column; justify-content: space-between">
        <slot name="widget" :data="$data" :update="update"></slot>
        <q-scroll-area class="custom-scroll-area" style="height: calc(100vh - 120px)">

          <q-list bordered separator>
            <q-item clickable v-ripple v-for="item in offline" :key="item.id" @click="setCurrentUnit(item)">
              <q-item-section style="text-align: left">
                {{item.name}}
              </q-item-section>
              <q-item-section style="text-align: center">
                {{getTimestamp(item)}}
              </q-item-section>
              <q-item-section style="text-align: right">
                {{timestamp(item)}}
              </q-item-section>
            </q-item>
          </q-list>



        </q-scroll-area>

<!--        <q-dialog-->
<!--            autofocus-->
<!--            v-model="openUnit"-->
<!--            persistent-->
<!--            :maximized="maximizedToggle"-->
<!--            transition-show="slide-up"-->
<!--            transition-hide="slide-down"-->
<!--            @keydown.esc="openUnit = false"-->
<!--        >-->
<!--          <q-card>-->
<!--            <q-bar class="bg-primary">-->
<!--              <div style="color: white">{{nameObject}}</div>-->
<!--              <q-space />-->
<!--              <q-btn flat icon="close" color="white" v-close-popup/>-->
<!--            </q-bar>-->

<!--            <q-layout view="hhh LpR ffr" style="min-height: calc(100vh - 150px)">-->
<!--              <unit :id="id" />-->
<!--            </q-layout>-->

<!--          </q-card>-->

<!--        </q-dialog>-->



<!--        <unit-dialog :id="id" :nameObject="nameObject" :openUnit="openUnit" v-on:close-unit-dialog="openUnit = false"/>-->
        <unit-dialog :id="id" :openUnit="openUnit" v-on:close-unit-dialog="closeUnitDialog"/>

      </q-card>
</template>

<script>
import moment from "moment";
import UnitDialog from "../components/UnitDialog";
import {mapGetters} from "vuex";
import {widget} from "./mixins/widget";

export default {
  name: "OfflineWidget",
  components: { UnitDialog },
  mixins: [widget],
  data() {
    return {
      name: 'Транспорт не на связи',
      total: null,
      // icon: 'fas fa-truck',
      id: null,
      // nameObject: null,
      offline: [],
      openUnit: false,
      maximizedToggle: true,
    }
  },

  async mounted() {

  },

  methods: {
    // checkoutVisibility() {
    //   if(!this.updatedProps) return
    //   this.updatedProps = false
    //   setTimeout(() => this.updatedProps = true, 300)
    // },

    async fetch() {
      let result = null

      try {
        result = await this.server.items('unit').readMany({
          limit: -1,
          meta: '*',

          filter: {
            position: {
              date_updated: {
                _lte: '$NOW(-5 minutes)'
              }
            }
          },

          fields: ['id', 'name', 'device.ident', 'position.date_updated'],
        })
      }
      catch (e) {
        console.debug('fetching error', e)
        this.$q.notify({
          message: `Ошибка получения онлайн: ${e.message}`
        })
      }

      if(result) {
        this.offline = _.sortBy(result.data, x => x.position[0].date_updated)
        this.total = result.meta.filter_count
        console.debug(this.offline)
      }
    },

    timestamp(item) {
      const now = new Date()
      if(!item.position[0]?.date_updated) return 'неизвестно'
      const time = new Date(item.position[0].date_updated)
      const diff = time - now
      return moment.duration(diff, 'millisecond').humanize(true)
    },

    getTimestamp(item) {
      if(!item.position[0]?.date_updated) return 'неизвестно'
      // return moment(item.position[0]?.date_updated).format('YYYY-MM-DD HH:mm:ss')
      return moment(item.position[0]?.date_updated).format('LLL')
    },

    setCurrentUnit(item) {
      this.openUnit = true
      this.id = item.id
      // this.nameObject = item.name
    },

    closeUnitDialog() {
      this.openUnit = false
      this.$emit('close-unit-dialog')
    }
  },

  computed: {
    ...mapGetters({
      'server': "server/instance"
    }),
  }
}
</script>

<style scoped>

</style>