<template>
  <div style="height: 100%">
    <slot name="widget" :update="update" :data="$data" :props="$props" :apexChartData="donutStatusData" :apexChartOptions="donutStatusOptions" :apexChartData2="donutPeriodData" :apexChartOptions2="donutPeriodOptions"></slot>
  </div>
</template>

<script>
import _ from "lodash";
import {widget} from "./mixins/widget";

let countsPeriod = {
  day: 0,
  week: 0,
  month: 0,
  total: 0
}

let countsStatus = {
  done: 0,
  progress: 0,
  queue: 0,
  error: 0,
  total: 0
}

export default {
  name: "TasksWidgetAll",
  mixins: [widget],

  data() {
    return {
      name: 'Задания',
      total: 0,
      // icon: 'far fa-play-circle',
      day: 0,
      week: 0,
      month: 0,
      statuses: [],
      totalPeriod: 0
    }
  },

  async mounted() {

  },

  methods: {
    // checkoutVisibility() {
    //   if(!this.updatedProps) return
    //   this.updatedProps = false
    //   setTimeout(() => this.updatedProps = true, 300)
    // },

    async fetchStatuses() {
      let result

      try {
        result = await this.server.items('load_video_task').readMany({
          groupBy: ['status'],
          aggregate: {
            count: 'id'
          }
        })
      }
      catch (e) {
        console.error('error fetch tasks', e)

        this.$q.notify({
          message: `Ошибка загрузки заданий: ${e.message}`,
          color: 'red'
        })
      }

      this.statuses = result.data
      console.debug(this.statuses)
    },

    async fetchCounts() {
      try {
        this.day = (await this.server.items('load_video_task').readMany({
          fields: ['id'],
          limit: 0,
          meta: 'filter_count',

          filter: {
            date_created: {
              _gte: '$NOW(-1 day)'
            }
          }
        })).meta.filter_count

        this.week = (await this.server.items('load_video_task').readMany({
          fields: ['id'],
          limit: 0,
          meta: 'filter_count',

          filter: {
            date_created: {
              _gte: '$NOW(-1 week)'
            }
          }
        })).meta.filter_count

        this.month = (await this.server.items('load_video_task').readMany({
          fields: ['id'],
          limit: 0,
          meta: 'filter_count',

          filter: {
            date_created: {
              _gte: '$NOW(-1 month)'
            }
          }
        })).meta.filter_count
      }
      catch (e) {
         console.debug(`Error: ${e}`)
      }
      console.debug(this.day)
      console.debug(this.week)
      console.debug(this.month)
    },

    getTotalCountStatus() {
      // console.debug(_.sumBy(this.statuses, x => parseInt(x.count.id)))
      this.total = _.sumBy(this.statuses, x => parseInt(x.count.id))
    },

    getTotalCountPeriod() {
      this.totalPeriod = this.day + this.week + this.month
    },

    async fetch() {
      await this.fetchStatuses()
      this.getTotalCountStatus()
      await this.fetchCounts()
      this.getTotalCountPeriod()

      countsPeriod = {
        day: this.day,
        week: this.week,
        month: this.month,
        total: this.day + this.week + this.month
      }

      this.statuses.forEach(item => {
        if (item.status === "done") {
          countsStatus.done = Number(item.count.id)
        }
        if (item.status === "progress") {
          countsStatus.progress = Number(item.count.id)
        }
        if (item.status === "queue") {
          countsStatus.queue = Number(item.count.id)
        }
        if (item.status === "error") {
          countsStatus.error = Number(item.count.id)
        }
      })

      countsStatus.total = countsStatus.done + countsStatus.progress + countsStatus.queue + countsStatus.error
    }
  },

  computed: {
    donutStatusData() {
      return this.statuses.map(item => (Number(item.count.id)*100)/this.total)
    },

    donutStatusLabels() {
      return this.statuses.map(item => {
          if(item.status === 'done') return 'Завершено'
          if(item.status === 'progress') return 'В процессе'
          if(item.status === 'queue') return 'В очереди'
          if(item.status === 'error') return 'Ошибка'
      })
    },

    donutStatusOptions() {
      return {
        chart: {
          type: 'donut',
        },
        labels: this.donutStatusLabels,
        // theme: {
        //   palette: this.chartsPallet,
        // },
        colors: this.chartsColors,
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                },
                value: {
                  show: true,
                  formatter: function(value) {
                  //   console.debug(value)
                    // return Number(value).toFixed(1) + ' %'

                    // return value * countsStatus.total / 100

                    return Math.round(value * countsStatus.total / 100)
                  },
                },
                total: {
                  show: true,
                  showAlways: false,
                  label: 'Всего',
                  formatter: function (w) {
                    // console.debug(w.globals.seriesTotals.length)
                    console.debug(countsStatus.total)
                    // w.globals.seriesTotals.forEach(item => {
                    //   console.debug(item * memory.total / 100)
                    // })
                    // return w.globals.seriesTotals.reduce((a, b) => {
                    //   return (a * countsPeriod.total / 100) + (b * countsPeriod.total / 100)
                    // })

                    return countsStatus.total
                  }
                }
              }
            }
          }
        },
        tooltip: {
          y: {
            formatter: function(value) {
              console.debug(value)
              // return value.toFixed(1) + ' %'
              return Math.round(value * 10) / 10 + ' %'
            },
            title: {
              formatter: (seriesName) => seriesName + ':'
             }
            },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true,
          floating: false,
          fontSize: '16px',
          position: 'right',
          offsetX: 0,
          offsetY: 0,
          markers: {
            size: 0
          },
          formatter: function(seriesName) {
            let value = 0

            if(seriesName === 'Завершено') value = countsStatus.done
            else if(seriesName === 'В процессе') value = countsStatus.progress
            else if(seriesName === 'В очереди') value = countsStatus.queue
            else if(seriesName === 'Ошибка') value = countsStatus.error

            return `${seriesName} ${value}`
          },
          itemMargin: {
            vertical: 3
          }
        },
      }
    },

    dataSeries() {
      return _.sortBy([
        { label: 'За сутки', value: (this.day*100)/this.totalPeriod },
        { label: 'За неделю', value: (this.week*100)/this.totalPeriod },
        { label: 'За месяц', value: (this.month*100)/this.totalPeriod },
      ], x => x.value).reverse()
    },

    donutPeriodData() {
      return this.dataSeries.map(x => x.value)
    },

    donutPeriodOptions() {
      return {
        chart: {
          type: 'donut',
        },
        labels: this.dataSeries.map(x => x.label),
        // theme: {
        //   palette: this.chartsPallet,
        // },
        colors: this.chartsColors,
        // plotOptions: {
        //   radialBar: {
        //     labels: {
        //       show: false
        //     },
        //     dataLabels: {
        //
        //       name: {
        //         show: true,
        //       },
        //       value: {
        //         show: true,
        //         offsetY: 3,
        //       },
        //     }
        //   }
        // },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                },
                value: {
                  show: true,
                  formatter: function(value) {
                    // console.debug(value)
                    // return Number(value).toFixed(1) + ' %'
                    return Math.round(value * countsPeriod.total / 100)
                  },
                },
                total: {
                  show: true,
                  showAlways: false,
                  label: 'Всего',
                  formatter: function (w) {
                    // console.debug(w.globals.seriesTotals.length)
                    // console.debug(memory.total)
                    // w.globals.seriesTotals.forEach(item => {
                    //   console.debug(item * memory.total / 100)
                    // })
                    // return w.globals.seriesTotals.reduce((a, b) => {
                    //   return (a * countsPeriod.total / 100) + (b * countsPeriod.total / 100)
                    // })
                    return countsPeriod.month
                  }
                }
              }
            }
          }
        },
        tooltip: {
          y: {
            formatter: function(value) {
              console.debug(value)
              // return value.toFixed(1) + ' %'
              return Math.round(value * 10) / 10 + ' %'
            },
            title: {
              formatter: (seriesName) => seriesName + ':'
            }
          },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true,
          floating: false,
          fontSize: '16px',
          position: 'right',
          offsetX: 0,
          offsetY: 0,
          labels: {
            useSeriesColors: false,
          },
          markers: {
            size: 0
          },

          formatter: function(seriesName) {
            let value = 0

            if(seriesName === 'За месяц') value = countsPeriod.month
            else if(seriesName === 'За сутки') value = countsPeriod.day
            else if(seriesName === 'За неделю') value = countsPeriod.week

            return `${seriesName} ${value}`
          },

          itemMargin: {
            vertical: 3
          }
        },
      }
    },

    // totalCountPeriod() {
    //   return this.day + this.week + this.month
    // },
  }
}
</script>

<style scoped>

</style>