<template>
  <q-page class="q-gutter-y-md for-padding" style="min-height: auto">
    <q-layout view="hhh LpR ffr" style="min-height: auto">
      <q-header class="border-for-header" style="background-color: #ffffff;">
        <q-toolbar class="row items-center justify-between">
          <div class="row">
                <q-input dense filled v-model="dateFrom" mask="date" label="Начало:" style="margin-right: 10px">
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="dateFrom">
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Закрыть" color="primary" flat />
                            <q-btn v-close-popup label="Сбросить" color="primary" flat @click="dateFrom=''" />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
                <q-input dense filled v-model="dateTo" mask="date" label="Окончание:">
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="dateTo">
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Закрыть" color="primary" flat />
                            <q-btn v-close-popup label="Сбросить" color="primary" flat @click="dateTo=''" />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
          </div>
          <q-select
              dense
              style="width: 350px"
              borderless
              v-model="camera"
              :options="cameras"
              multiple
              use-chips
              label="Отображение камер">
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-italic text-grey">
                  Нет результата
                </q-item-section>
              </q-item>
            </template>
          </q-select>
          <q-select dense style="width: 250px" borderless v-model="directionDate" :options="directionsDate" label="Сортировка по дате"/>
          <q-select dense style="width: 250px" borderless v-model="perPage" :options="amountImages" label="Картинок на странице"/>
          <q-btn-toggle
              v-model="sizeImages"
              toggle-color="primary"
              :options="[
                    {label: '1x', value: 1},
                    {label: '0.75x', value: 0.75},
                    {label: '0.5x', value: 0.5}
                    ]"
              style="color: black"
          />
        </q-toolbar>
      </q-header>
      <q-footer class="border-for-footer" style="background-color: #ffffff;">
        <q-toolbar class="justify-center">
          <q-pagination
              v-model="currentPage"
              :max="countPages"
              :max-pages="5"
              @input="pageChanged"
              direction-links
              :boundary-numbers="false"
              boundary-links
          />
        </q-toolbar>
      </q-footer>
      <q-page-container style="padding-top: 0 !important;">
        <q-page style="background-color: transparent; background-image: none; min-height: auto">
          <q-scroll-area class="custom-scroll-area" style="height: calc(100vh - 120px)">
            <div v-if="showPreload" class="row items-center justify-center" style="min-height: calc(100vh - 120px)">
              <lottie-animation
                  ref="anim"
                  :animationData="require('../assets/preload-animation.json')"
                  :loop="true"
                  :autoPlay="true"
                  :speed="1"
                  style="width: 200px; height: 200px"
              />
            </div>
            <div v-else class="q-pa-md flex q-gutter-md justify-center">
              <q-card v-for="item in snapshots" :key="item.id" class="my-card unit__card" :style="cardWidth">
                <img class="unit__img" :style="imageStyle" native-context-menu :src="snapshotSrc(item)" :ratio="16/9" @click="openSnapshotDialog(item)">
                <q-card-section class="row justify-between">
                  <div class="text-subtitle2">{{item.camera.name}}</div>
                  <div class="text-subtitle2">{{getTimestamp(item)}}</div>
                  <div>{{size(item)}}</div>
                </q-card-section>
              </q-card>
              <snapshot-dialog :image="currentImage" :openSnapshot="openSnapshot" v-on:close-snapshot-dialog="openSnapshot = false"/>
            </div>
          </q-scroll-area>
        </q-page>
      </q-page-container>
    </q-layout>
  </q-page>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import LottieAnimation from 'lottie-web-vue';
import {countPages, perPageInt, humanFileSize, snapshotSrc} from '../utils';
import SnapshotDialog from "../components/SnapshotDialog";

const sizeImagesKey = 'snapshots_sizeImages';
const directionDateKey = 'snapshots_directionDate';
// const cameraKey = 'snapshots_camera';
const perPageKey = 'snapshots_perPage';
const dateFromKey = 'snapshots_dateFrom';
const dateToKey = 'snapshots_dateTo';


export default {
  name: "Snapshots",
  components: { LottieAnimation, SnapshotDialog},
  // props: ['id'],
  props: {
  //   unit: {
  //     required: true,
  //     type: Object
  //   },
    id: {
      required: false,
      type: Number
    }
  },

  data() {
    return {
      snapshots: [],
      count: 0,
      perPage: localStorage[perPageKey] ?? '25',
      currentPage: 1,
      amountImages: ['25', '50', '100'],
      directionsDate: ['сначала новые', 'по хронологии'],
      directionDate: localStorage[directionDateKey] ?? 'сначала новые',
      dateFrom: null,
      dateTo: null,
      showPreload: true,
      sizeImages: parseFloat(localStorage[sizeImagesKey] ?? 1),
      openSnapshot: false,
      currentImage: {
         image: {
           id: '',
           storage: ''
         }
      },
      camera: [],
      cameras: []
    }
  },

  async mounted() {
    // if(localStorage[this.dateToKey]) localStorage.removeItem(this.dateToKey)
    // if(localStorage[this.dateFromKey]) localStorage.removeItem(this.dateFromKey)
    this.dateTo = sessionStorage[this.dateToKey]
    this.dateFrom = sessionStorage[this.dateFromKey]
    await this.fetchCameras()
    await this.fetchSnapshots()
    this.showPreload = false
  },

  computed: {
    ...mapGetters({
      'server': 'server/instance'
    }),

    imageStyle() {
      return `height: ${253 * this.sizeImages}px`;
    },

    cardWidth() {
      return `width: ${24 * this.sizeImages}%`;
    },

    countPages() {
      return countPages(this.count, this.perPageInt)
    },

    perPageInt() {
      return perPageInt(this.perPage)
    },

    dateToKey() {
      return `${dateToKey}_${this.id}`
    },

    dateFromKey() {
      return `${dateFromKey}_${this.id}`
    },

    getFilter() {
      let filter = {
        camera: {
          unit: {
            id: {
              _eq: this.id
            }
          }
        },
      }
      const from = this.dateFrom ? new Date(`${this.dateFrom.replace(/\//g, '-')}T00:00:00`).toISOString() : null
      const to = this.dateTo ? new Date(`${this.dateTo.replace(/\//g, '-')}T23:59:59`).toISOString() : null

      if(from && !to) {
        filter.date_created = {
          _gte: from
        }
      }

      else if(to && !from) {
        filter.date_created = {
          _lte: to
        }
      }

      else if(to && from) {
        filter.date_created = {
          _between: [from, to]
        }
      }

      if (this.camera.length < this.cameras.length) {
        const camerasValueForFilter = this.camera.map((item) => item.value)
        filter.camera = {
          _in: camerasValueForFilter
        }
      }

      return filter
    }
  },

  watch: {
    async perPage(n) {
      localStorage.setItem(perPageKey, n)
      this.showPreload = true
      await this.fetchSnapshots()
      this.showPreload = false
    },

    async dateFrom(n) {
      if(!n) sessionStorage.removeItem(this.dateFromKey)
      else sessionStorage[this.dateFromKey] = n

      this.showPreload = true
      await this.fetchSnapshots()
      this.showPreload = false
    },

    async dateTo(n) {
      if(!n) sessionStorage.removeItem(this.dateToKey)
      else sessionStorage[this.dateToKey] = n

      this.showPreload = true
      await this.fetchSnapshots()
      this.showPreload = false
    },

    sizeImages(n) {
      localStorage.setItem(sizeImagesKey, n)
    },

    async directionDate(n) {
      localStorage.setItem(directionDateKey, n)
      this.showPreload = true
      await this.fetchSnapshots()
      this.showPreload = false
    },

    async camera(n, o) {
      console.debug(n)
      console.debug(o)
      if (n.length === 0) {
        this.camera.push(o[0])
      }
      if (n.length > 0) {
        if (n.length === 1 && o.length === 1 && n[0].value === o[0].value) {
           return
        }
        else {
          this.showPreload = true
          await this.fetchCount()
          await this.fetchSnapshots()
          this.showPreload = false
        }
      }
    },
  },

  methods: {
    snapshotSrc,

    size(item) {
        return item.image.filesize ? humanFileSize(item.image.filesize / 1000, true) : 'нет размера'
      },

    async pageChanged() {
      this.showPreload = true
      await this.fetchSnapshots()
      this.showPreload = false
    },

    getTimestamp(snapshot) {
      // return moment(snapshot.date_created).format('YYYY-MM-DD HH:mm:ss')
      return moment(snapshot.date_created).format('LLL')
    },

    async fetchCameras() {
      const result = await this.server.items('camera').readMany({
        filter: {
          unit: {
            id: {
              _eq: this.id
            }
          }
        },
        limit: -1,
        sort: ['-channel'],
        fields: ['id', 'name', 'channel']
      })

      const prepared = result.data.map(x => {
        return {
          label: x.name ? `${x.name} (${x.channel})` : `канал: ${x.channel}`,
          value: x.id
        }
      })

      this.cameras = prepared
      this.camera = prepared
    },

    async fetchCount() {
      const query = {
        filter: this.getFilter,
        meta: 'filter_count',
        limit: 0,
      }

      try {
        const result = await this.server.items('snapshot').readMany(query)
        this.count = result.meta.filter_count
      }
      catch (e) {
        console.error('fetch error', e)
        this.$q.notify({
          message: `Ошибка получения количества изображений: ${e.message}`,
          color: 'red'
        })
      }
      // console.debug(this.count)
    },

    async fetchSnapshots() {
      const query = {
        filter: this.getFilter,
        meta: 'filter_count',
        fields: ['id', 'image.filesize', 'image.id', 'date_created',
          'camera.id', 'camera.name', 'camera.channel', 'image.storage', 'image.filename_disk'],
        sort: [this.directionDate === 'сначала новые' ? '-date_created' : 'date_created'],
        limit: this.perPageInt,
        page: this.currentPage
      }

      try {
        const result = await this.server.items('snapshot').readMany(query)
        this.count = result.meta.filter_count
        this.snapshots = result.data
      }
      catch (e) {
        console.error('fetch error', e)
        this.$q.notify({
          message: `Ошибка получения изображений: ${e.message}`,
          color: 'red'
        })
      }
      // console.debug(this.count)
    },

    openSnapshotDialog(value) {
      this.currentImage = value
      this.openSnapshot = true
    }
  }
}

</script>

<style scoped>
.custom-scroll-area {
  padding-top: 50px;
}
.unit__img {
  cursor: pointer;
}
.for-padding * .q-page-container {
  padding-top: 64px !important;
}
.custom-q-pa-md {
  padding: 16px 10px;
}
</style>