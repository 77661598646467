<template>
  <q-page>
    <l-map ref="track-map" v-if="!preparingMap" style="width: 100%; height: calc(60vh)" :zoom="zoom" :center="center">
      <l-tile-layer :url="url"/>

      <l-polyline v-if="trackData" :weight="3" :lat-lngs="latlngs" color="green"/>

      <v-marker-cluster :options="{ maxClusterRadius: 20 }">
        <l-marker v-for="s in snapshotMarkerDatas" :visible="snapshotsVisible" :lat-lng="[s.lat, s.lon]" :key="s.id" :icon="photoIcon" @click="openSnapshot(s)">
          <l-tooltip>
<!--            <img :src="`https://files.lvs.logexpert.ru/image/${s.id}`" alt="" class="snapshot-preview">-->
            <div>
              <b>{{ s.camera.name }}</b>
              <div> {{ DateTime.fromISO(s.date_created).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) }} </div>
            </div>
          </l-tooltip>
        </l-marker>

        <l-marker v-for="p in parkings" :lat-lng="p.latlng" :key="p.begin" :icon="parkingIcon" @click="createTask(p)">
          <l-tooltip>
            Стоянка: {{ DateTime.fromMillis(p.begin).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) }} - {{ DateTime.fromMillis(p.end).diff(DateTime.fromMillis(p.begin), ['seconds', 'minutes', 'hours']).toHuman() }}
          </l-tooltip>
        </l-marker>
      </v-marker-cluster>

      <l-polyline v-for="v in videosPolylineData" :lat-lngs="v.latlngs" :key="v.id" color="blue" @click="playVideo(v)">
        <l-tooltip>
          Видео {{new Date(v.begin).toLocaleString()}} - {{new Date(v.end).toLocaleString()}}
        </l-tooltip>
      </l-polyline>
    </l-map>

    <q-dialog v-model="openLoadTaskDialog">
      <q-card style="display: flex; flex-direction: column; width: 50% !important; max-width: 50% !important;">
        <video :src="`https://files.lvs.logexpert.ru/video/${selectedVideo.id}`" autoplay controls style="width: 100%;"/>
      </q-card>
    </q-dialog>

    <q-dialog v-model="openSnapshotDialog">
      <q-card style="display: flex; flex-direction: column; width: 50% !important; max-width: 50% !important;">
        <img :src="`https://files.lvs.logexpert.ru/image/${selectedSnapshot}`" style="width: 100%;" alt=""/>
      </q-card>
    </q-dialog>

    <q-dialog v-if="selectedUnit && selectedParking" v-model="openLoadTaskCreate">
      <load-task-create-form :id="selectedUnit.value"
                             :date="DateTime.fromMillis(selectedParking.begin).toFormat('yyyy/LL/dd')"
                             :begin-time="DateTime.fromMillis(selectedParking.begin).toLocaleString(DateTime.TIME_24_SIMPLE)"
                             :end-time="DateTime.fromMillis(selectedParking.end).toLocaleString(DateTime.TIME_24_SIMPLE)"
      />
    </q-dialog>

    <q-card class="interval-control">
      <q-card-section class="text-center" v-ripple>
        <q-btn flat>
          <div>
            {{ begin.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) }}
          </div>

          <div>
            {{ end.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) }}
          </div>

          <q-popup-proxy>
            <q-date v-model="calendarDate" mask="YYYY-MM-DD"/>
          </q-popup-proxy>
        </q-btn>
      </q-card-section>

      <q-card-actions align="around">
        <q-btn flat icon="history" @click="prevDateRange"/>
        <q-btn flat icon="schedule" @click="defaultDateRange"/>
        <q-btn flat icon="update" @click="nextDateRange"/>
      </q-card-actions>

      <q-card-section>
        <q-select
            filled
            v-model="selectedUnit"
            use-input
            label="Выберите объект"
            :options="selectOptions"
            @filter="filterFn"
            behavior="menu"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </q-card-section>
    </q-card>

    <div class="q-pa-md row items-start q-gutter-sm row" style="height: 35.4vh">

      <div class="col-3">
        <q-card>
          <q-card-section>
            Информация
          </q-card-section>
          <q-card-section style="height: 27vh">
            <q-scroll-area style="height: 100%">
              <q-list dense>
                <q-item>
                  <q-item-section>
                    <q-item-label>Пробег</q-item-label>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label v-if="trackData">{{mileage}} км</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item v-if="points" clickable @click="moveMap([points[0].lat, points[0].lon])">
                  <q-item-section>
                    <q-item-label>Первое сообщение</q-item-label>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ DateTime.fromMillis(trackData.points[0].timestamp).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) }}</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item v-if="points" clickable @click="moveMap([points[points.length - 1].lat, points[points.length - 1].lon])">
                  <q-item-section>
                    <q-item-label>Последнее сообщение</q-item-label>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ DateTime.fromMillis(points[points.length - 1].timestamp).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) }}</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item class="row justify-between">
                  <q-item-section>
                    <q-item-label>Сообщений</q-item-label>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label v-if="points" class="col">
                      {{points.length}}
                    </q-item-label>
                    <q-item-label v-else>
                      ???
                    </q-item-label>
                  </q-item-section>
                </q-item>

                <q-item>
                  <q-item-section>
                    <q-item-label>Фотографий</q-item-label>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label v-if="snapshots">{{snapshots.length}}</q-item-label>
                    <q-item-label v-else>
                      ???
                    </q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <q-checkbox v-model="snapshotsVisible"/>
                  </q-item-section>
                </q-item>

              </q-list>
            </q-scroll-area>
          </q-card-section>
        </q-card>
      </div>

      <div class="col">
        <q-card style="height: 100%">
          <q-card-section>
            Стоянки
          </q-card-section>

          <q-card-section style="height: 27vh">
            <q-scroll-area style="height: 100%">
              <q-list dense separator>
                <q-item dense v-for="p in parkings" :key="p.begin" clickable @click="moveMap(p.latlng)">
                  <q-item-section>
<!--                    <q-item-label>{{ p.begin }}</q-item-label>-->
                    <q-item-label>{{ DateTime.fromMillis(p.begin).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) }}</q-item-label>
                    <q-item-label caption lines="2">Адрес стоянки</q-item-label>
                  </q-item-section>

                  <q-item-section>
                    <q-item-label>
                      {{ DateTime.fromMillis(p.end).diff(DateTime.fromMillis(p.begin), ['seconds', 'minutes', 'hours']).toHuman() }}
                    </q-item-label>
                  </q-item-section>

                  <q-item-section side>
                    <q-btn color="green" rounded dense icon="add" @click="createTask(p)"/>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-scroll-area>
          </q-card-section>
        </q-card>
      </div>

      <div class="col">
        <q-card style="height: 100%">
          <q-card-section>
            Видео
          </q-card-section>

          <q-card-section style="height: 27vh">
            <q-scroll-area style="height: 100%">
              <q-list dense separator>
                <q-item dense v-for="v in videos" :key="v.id" clickable @click="moveToVideo(v)">
                  <q-item-section>
                    <q-item-label>
                      {{ DateTime.fromISO(v.begin).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) }}
                    </q-item-label>
                  </q-item-section>

                  <q-item-section>
                    <q-item-label>
                      {{ DateTime.fromISO(v.end).diff(DateTime.fromISO(v.begin), ['seconds', 'minutes', 'hours']).toHuman() }}
                    </q-item-label>
                  </q-item-section>

                  <q-item-section side>
                    <q-btn color="blue" rounded dense icon="camera" @click="playVideo(v)"/>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-scroll-area>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </q-page>
</template>

<script>

import {LMap, LMarker, LPolyline, LTileLayer, LTooltip} from "vue2-leaflet";
import L from 'leaflet'
import {mapActions, mapGetters} from "vuex";
import 'leaflet/dist/leaflet.css';
import { DateTime } from 'luxon'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import LoadTaskCreateForm from "@/components/LoadTaskCreateForm";
import _ from "lodash";

const defaultPosition = [50.595414, 36.587277]

async function getCurrentPosition() {
  if(!navigator.geolocation) return defaultPosition

  try {
    const pos = await new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject))
    return [pos.coords.latitude, pos.coords.longitude]
  }
  catch (e) {
    console.warn('error get position', e)
    return defaultPosition
  }
}

function defaultBegin() {
  return DateTime.fromObject({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  })
}

function defaultEnd() {
  return DateTime.fromObject({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0
  })
}

export default {
  name: "Tracks",

  components: {
    LoadTaskCreateForm,
    LMap,
    LTileLayer,
    LPolyline,
    LMarker,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    LTooltip
  },

  data() {
    return {
      DateTime: DateTime,

      preparingMap: true,
      zoom: 15,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      center: null,

      trackData: null,

      begin: defaultBegin(),
      end: defaultEnd(),

      openLoadTaskDialog: false,
      openSnapshotDialog: false,

      selectedVideo: {
        id: '6319bc9a-dea4-44c6-9f2f-e229ff941ff5'
      },

      selectedParking: null,
      openLoadTaskCreate: false,
      
      selectedSnapshot: null,

      selectOptions: null,
      selectedUnit: null,

      snapshotsVisible: true,

      gis_id: null,

      snapshots: null,

      parkingSelected: null,

      videos: [],

      parkingIcon: L.icon({
        iconUrl: 'https://lvs-cms.logexpert.ru/assets/22502c45-a297-4120-b84f-9f0e1b961707',
        iconSize: [32, 32]
      }),

      photoIcon: L.icon({
        iconUrl: 'https://lvs-cms.logexpert.ru/assets/cd2ecbac-623e-4d1f-8940-a3420be21731',
        iconSize: [32, 32]
      })

      // trackIsLoading: false // todo: реализовать индикатор пока загружаются сообщения
    }
  },

  async mounted() {
    this.setTitle('Треки')

    this.center = await getCurrentPosition()
    this.preparingMap = false
  },

  computed: {
    ...mapGetters({
      'server': 'server/instance'
    }),

    points() {
      return this.trackData?.points?.length ? this.trackData.points : null
    },

    events() {
      return this.trackData?.events?.length ? this.trackData.events : null
    },

    calendarDate: {
      get() {
        return this.begin.toISODate()
      },

      set(val) {
        console.log('calendarDate set', val)
        const date = DateTime.fromMillis(Date.parse(val))

        this.begin = DateTime.fromObject({
          month: date.month,
          day: date.day,
          hour: this.begin.hour,
          minute: this.begin.minute,
          second: this.begin.second,
          millisecond: this.begin.millisecond
        })

        this.end = DateTime.fromObject({
          month: date.month,
          day: date.day,
          hour: this.end.hour,
          minute: this.end.minute,
          second: this.end.second,
          millisecond: this.end.millisecond
        })

        this.updateTrack()
      }
    },

    latlngs() {
      return this.trackData.points.map(x => [x.lat, x.lon])
    },

    mileage() {
      return !this.events ? '???' : ((_.sumBy(this.events, x => x.mileage) ?? 0) / 1000).toFixed(3)
    },

    snapshotMarkerDatas() {
      if(!this.snapshots || !this.points) return []

      return this.snapshots.map(x => {
        const point = this.points.find(p => p.timestamp >= new Date(x.date_created).getTime())

        return {
          ...x,
          ...point
        }
      })
    },

    parkings() {
      return this.trackData?.events?.filter(x => x.type === 'Parking')?.map(x => {
        return {
          ...x,
          latlng: this.points.find(p => p.timestamp >= x.begin)
        }
      }).filter(x => x.latlng) ?? []
    },

    videosPolylineData() {
      return this.videos.filter(v => v.status === 'done').map(v => {
        const begin = Date.parse(v.begin)
        const end = Date.parse(v.end)

        return {
          begin: begin,
          end: end,
          id: v.id,
          camera: v.camera.name,
          latlngs: this.points.filter(p => p.timestamp >= begin && p.timestamp <= end).map(p => [p.lat, p.lon])
        }
      })
    },
  },

  watch: {
    selectedUnit(n) {
      if(!n) return
      this.updateTrack()
    }
  },

  methods: {
    ...mapActions({
      'setTitle': 'layout/setToolbarTitle'
    }),

    moveToVideo(v) {
      const point = this.points.find(x => x.timestamp >= new Date(v.begin).getTime())
      this.moveMap([point.lat, point.lon])
    },

    createTask(p) {
      this.selectedParking = p
      this.openLoadTaskCreate = true
    },

    moveMap(latlng) {
      this.$refs['track-map'].mapObject.panTo(latlng)
    },

    prevDateRange() {
      const dayDelta = this.end.day - this.begin.day + 1

      const begin = this.begin.minus({ days: dayDelta })

      this.begin = DateTime.fromObject({
        month: begin.month,
        day: begin.day,
        hour: this.begin.hour,
        minute: this.begin.minute,
        second: this.begin.second,
        millisecond: this.begin.millisecond
      })

      const end = this.end.minus({ days: dayDelta })

      this.end = DateTime.fromObject({
        month: end.month,
        day: end.day,
        hour: this.end.hour,
        minute: this.end.minute,
        second: this.end.second,
        millisecond: this.end.millisecond
      })

      this.updateTrack()
    },

    nextDateRange() {
      const dayDelta = this.end.day - this.begin.day + 1

      const begin = this.begin.plus({ days: dayDelta })

      this.begin = DateTime.fromObject({
        month: begin.month,
        day: begin.day,
        hour: this.begin.hour,
        minute: this.begin.minute,
        second: this.begin.second,
        millisecond: this.begin.millisecond
      })

      const end = this.end.plus({ days: dayDelta })

      this.end = DateTime.fromObject({
        month: end.month,
        day: end.day,
        hour: this.end.hour,
        minute: this.end.minute,
        second: this.end.second,
        millisecond: this.end.millisecond
      })

      this.updateTrack()
    },

    defaultDateRange() {
      this.begin = defaultBegin()
      this.end = defaultEnd()

      this.updateTrack()
    },

    async filterFn(val, update) {
      const filter = {
        gis_id: { _nnull: true },
        gis_connection: { _nnull: true }
      }

      if(val) filter.name = {
        _contains: val
      }

      const units = await this.server.items('unit').readMany({
        filter,
        fields: ['name', 'id']
      })

      update(() => {
        this.selectOptions = units.data.map(u => {
          return {
            label: u.name,
            value: u.id
          }
        })
      })
    },

    async updateTrack() {
      this.messages = null
      this.snapshots = null
      this.videos = []

      if(!this.selectedUnit) return

      if(!this.begin || !this.end) {
        console.warn('no begin or end date')
        return
      }

      const begin = new Date(this.begin.toMillis()).toISOString()
      const end = new Date(this.end.toMillis()).toISOString()

      console.log(begin, end)

      const result = await this.server.transport.get(`tracks/${this.selectedUnit.value}`, {
        params: {
          begin,
          end
        }
      })

      this.trackData = result.data

      if(result.data.points?.length) {
        const fp = result.data.points[0]
        this.center = [fp.lat, fp.lon]
      }
      else return

      const points = result.data.points

      const actualBegin = new Date(points[0].timestamp).toISOString()
      const actualEnd = new Date(points[points.length - 1].timestamp).toISOString()

      this.updateSnapshots(actualBegin, actualEnd)
      this.updateVideos(actualBegin, actualEnd)
    },

    async updateSnapshots(begin, end) {
      const snapshotsResult = await this.server.items('snapshot').readMany({
        filter: {
          camera: {
            unit: {
              id: { _eq: this.selectedUnit.value }
            }
          },

          date_created: {
            _between: [begin, end]
          }
        },

        fields: ['id', 'date_created', 'camera.name'],

        sort: 'date_created',
        limit: -1
      })

      this.snapshots = snapshotsResult.data
    },

    async updateVideos(begin, end) {
      const videosResult = await this.server.items('load_video_task').readMany({
        filter: {
          begin: { _gte: begin },
          end: { _lte: end }
        },

        fields: ['status', 'camera.name', 'begin', 'end', 'id']
      })

      this.videos = videosResult.data
    },

    playVideo(v) {
      console.log('play video data', v)

      this.selectedVideo = v
      this.openLoadTaskDialog = true
    },

    openSnapshot(s) {
      this.selectedSnapshot = s.id
      this.openSnapshotDialog = true
    }
  }
}
</script>

<style  scoped>
.snapshot-preview {
  height: calc(40vh);
}

.interval-control {
  position: absolute;
  z-index: 1500;
  top: 0;
  right: 0;
  background-color: rgba(245, 245, 245, 0.5);
  margin-right: 5vh;
  margin-top: 2vh;
  border: 1px solid lightgray;
  width: 30vh;
  max-height: 40vh;
}

.list-section {
  max-width: 50vh;
  background-color: whitesmoke;
}
</style>