<template>
  <q-card style="position:relative; display: flex; flex-direction: column; justify-content: space-between; min-width: 350px; min-height: 316px; width: 47vw; height: 42vw">

    <div v-if="requested" style="position: absolute; top: 0; left: 0; z-index: 6; display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%; height: 91.75%">

<!--      <q-spinner-->
<!--          color="blue-1"-->
<!--          size="10em"-->
<!--          :thickness="5"-->
<!--      />-->


<!--      <lottie-animation-->
<!--          ref="anim"-->
<!--          :animationData="getAnimation"-->
<!--          :loop="true"-->
<!--          :autoPlay="true"-->
<!--          :speed="1"-->
<!--          style="width: 200px; height: 200px;"-->
<!--      />-->

   <div v-show="!connected && !playing" style="display: flex; flex-direction: column; align-items: center">
      <lottie-animation
          ref="anim"
          :animationData="require('../assets/connecting-white.json')"
          :loop="true"
          :autoPlay="true"
          :speed="1"
          style="width: 200px; height: 200px"
      />
     <div class="text-h5" style="color: white; font-weight: 600">Соединяемся с устройством</div>
   </div>

    <div v-show="connected && !playing" style="display: flex; flex-direction: column; align-items: center">
      <lottie-animation
          ref="anim"
          :animationData="require('../assets/loading-white.json')"
          :loop="true"
          :autoPlay="true"
          :speed="1"
          style="width: 200px; height: 200px"
      />
      <div class="text-h5" style="color: white; font-weight: 600">Ожидаем видео поток...</div>
    </div>

<!--      <lottie-animation-->
<!--          ref="anim"-->
<!--          :animationData="require('../assets/connecting-white.json')"-->
<!--          :loop="true"-->
<!--          :autoPlay="true"-->
<!--          :speed="1"-->
<!--          style="width: 200px; height: 200px"-->
<!--      />-->
<!--      <lottie-animation-->
<!--          ref="anim"-->
<!--          :animationData="require('../assets/loading-white.json')"-->
<!--          :loop="true"-->
<!--          :autoPlay="true"-->
<!--          :speed="1"-->
<!--          style="width: 200px; height: 200px"-->
<!--      />-->

    </div>


    <img v-if="camera.last_snapshot && !playing" :src="snapshotSrc(camera.last_snapshot)" style="position: absolute; top: 0; left: 0; z-index: 5; width: 100%; height: 91.75%"/>

    <div v-if="!camera.last_snapshot && !playing" class="row bg-grey items-center justify-center" style="width: 100%; height: 91.75%; position: absolute; top: 0; left: 0; z-index: 5" >
      <img src="../assets/no-image.png"/>
      <div style="position: absolute; top: 70%;">Нет последнего снимка</div>
    </div>

    <video :id="camera.id" muted style="width: 100%; height: 91.75%"></video>

    <q-card-actions class="row justify-between">
      <div class="text-subtitle1">{{camera.name}}</div>
      <div v-if="speed !== null" class="text-subtitle1">{{speed}} KB / s</div>
      <div>
        <q-btn v-if="playing" dense round flat color="primary" icon="fullscreen" @click="fullscreen"/>
        <q-btn v-if="playing" dense round flat color="negative" icon="stop" @click="stop"/>
        <q-btn v-else dense round flat color="primary" icon="play_arrow" @click="play" :disable="requested"/>
      </div>
    </q-card-actions>
  </q-card>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LottieAnimation from 'lottie-web-vue';
import Hls from 'hls.js'
import axios from "axios";
import {snapshotSrc, timeout} from '../utils'

const MANIFEST_NAME = 'out.m3u8'
const STREAM_URL = 'https://streaming.lvs.logexpert.ru'


export default {
  name: "CameraStream",
  components: {LottieAnimation},

  props: {
    camera: {
        required: true,
        type: Object
      }
    },

  data() {
    return {
      playing: false,
      requested: false,
      connected: false,
      loaded: false,
      hls: new Hls(),
      is_destroyed: false,
      timeoutID: null,
      speed: null
    }
  },

  async mounted() {
    const status = await this.getStatus()
    if(status.connected && status.file_exists) await this.play(false)
  },

  destroyed() {
    this.is_destroyed = true
    this.hls.destroy()
    clearInterval(this.timeoutID)
  },


  computed: {
    ...mapGetters({
      'server': 'server/instance'
    })
  },

  methods: {
    ...mapActions({
      // 'setTitle': 'layout/setToolbarTitle'
    }),

    snapshotSrc,

    async updateSpeed() {
      if(this.is_destroyed || !this.playing) return
      const status = await this.getStatus()
      this.speed = Math.round(status.kbps)
    },

    // requestFullscreen() {
    //   document.getElementById(this.camera.id).requestFullscreen()
    // },

    fullscreen() {
      const video = document.getElementById(this.camera.id)
      video.requestFullscreen()
    },

    async getStatus() {
      const result = await axios.get(`${STREAM_URL}/status/${this.camera.id}`)
      return result.data.data
    },

    async play(checkStatus = true) {
      console.debug('start play by', this.camera.id)

      if(!checkStatus) this.playing = true

      if(checkStatus) {
        try {
          const status = await this.getStatus()
          console.debug(status)
          if(!status.connected) {
            this.requested = true
            await this.openStream()
          }
        }
        catch (e) {
          this.$q.notify({
            message: `Ошибка воспроизведения: ${e.message}. Попробуйте снова!`,
            color: 'red'
          })

          this.requested = false
          return
        }
      }

      try {
        await this.loadManifest()
      }
      catch (e) {
        this.$q.notify({
          message: `Ошибка воспроизведения: ${e.message}. Попробуйте снова!`,
          color: 'red'
        })

        return
      }
      finally {
        this.requested = false
      }

      if(this.is_destroyed) return

      this.playing = true

      const video = document.getElementById(this.camera.id)
      this.hls.attachMedia(video)
      video.play()

      this.timeoutID = setInterval(this.updateSpeed, 500)

      this.hls.on(Hls.Events.ERROR, (event, data) => {
        console.error('error playing', data)

        if(data.type !== 'networkError') return

        this.$q.notify({
          message: `${this.camera.name} воспроизведение завершилось с ошибкой`,
          color: 'red'
        })

        this.stop()
      })
    },

    stop() {
      this.playing = false
      this.requested = false
      this.connected = false
      this.hls.stopLoad()
      clearInterval(this.timeoutID)
      this.speed = null
    },

    async loadManifest() {
      const url = `${STREAM_URL}/stream/${this.camera.id}/${MANIFEST_NAME}`
      const hls = this.hls

      let start = Date.now()

      const tryLoad = () => new Promise((resolve, reject) => {
        console.debug('try load manifest')

        hls.once(Hls.Events.ERROR, (event, data) => {
          console.debug('got event error from hls')
          reject(data)
        })

        hls.once(Hls.Events.MANIFEST_PARSED, (event, data) => {
          console.debug('got event parsed from hls')
          resolve(data)
        })

        hls.loadSource(url)
        console.debug('invoked load source')
      })

      while (Date.now() - start < 12_000) {
        console.debug(`while step in loading manifest [timeout: ${(Date.now() - start) / 1000} / 12 sec]`)
        if(this.is_destroyed) {
          console.debug(`${this.camera.name} while load is stopped`)
          return
        }

        const status = await this.getStatus()

        if(!this.connected && status.connected) {
          this.connected = true
        }
        else if(this.connected && !status.connected) break

        if(!status.connected || (status.connected && !status.file_exists)) {
          if(status.connected && !status.file_exists) start = Date.now()

          await timeout(500)
          continue
        }

        try {
          await tryLoad()
          return
        }
        catch (e) {
          console.debug('error load manifest', e)
        }
      }

      throw new Error()
    },

    openStream() {
      const url = this.server.transport.url
      const id = this.camera.id
      return axios.get(`${url}/cameras/${id}/open-stream?access_token=${this.server.auth.token}`)
    },

  }
}
</script>

<style lang="sass" scoped>
@import "src/styles/quasar.variables.sass"

.padding
  margin: 1%
</style>