<template>
<!--  <q-page style="background-color: whitesmoke;">-->
<!--    <q-layout view="hhh LpR ffr" container>-->
  <q-page class="custom-q-page">
    <q-layout view="hhh LpR ffr" container class="custom-q-layout">

      <q-page-container>
        <q-page class="custom-q-page">

          <div v-if="showPreload" class="row items-center justify-center" style="min-height: calc(100vh - 180px)">

            <lottie-animation
                ref="anim"
                :animationData="require('../assets/preload-animation.json')"
                :loop="true"
                :autoPlay="true"
                :speed="1"
                style="width: 200px; height: 200px"
            />
          </div>

          <div v-else class="row justify-center q-pa-md q-gutter-md">
              <camera-stream :camera="camera" v-for="camera in cameras" :key="camera.id" style="display: flex; flex-direction: column;"/>
          </div>

        </q-page>
      </q-page-container>
    </q-layout>
  </q-page>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
// import {humanFileSize, perPageInt, countPages} from '../utils';
import LottieAnimation from 'lottie-web-vue';
import Hls from 'hls.js'
import axios from "axios";
import CameraStream from "../components/CameraStream";

const MANIFEST_NAME = 'out.m3u8'
const STREAM_URL = 'https://streaming.lvs.logexpert.ru/stream'

export default {
  name: "Cameras",
  components: {LottieAnimation, CameraStream},
  // props: ['id'],

  props: {
    id: {
        required: false,
        type: Number
      }

      // unit: {
      //   required: false,
      //   type: Object
      // }
    }
  ,

  data() {
    return {
      showPreload: true,
      cameras: [],
    }
  },

  async mounted() {
    await this.fetch()
    this.showPreload = false
  },

  computed: {
    ...mapGetters({
      'server': 'server/instance'
    }),
  },

  methods: {
    ...mapActions({
      // 'setTitle': 'layout/setToolbarTitle'
    }),

    async fetch() {
      let result

      try {
        result = await this.server.items('camera').readMany({
          fields: ['id', 'name', 'last_snapshot.image.id', 'last_snapshot.image.storage'],
          filter: {
                unit: {
                  id: {
                    _eq: this.id
                  }
                }
              },
        })
      } catch (e) {
        console.error('error fetch tasks', e)
        this.$q.notify({
          message: `Ошибка загрузки данных: ${e.message}`,
          color: 'red'
        })
      }

      this.cameras = result.data
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/styles/quasar.variables.sass"

.padding
  margin: 1%
</style>