<template>
  <q-page>
    <q-layout view="lhh LpR lff" container style="height: 95vh" class="bg-grey-3">
      <q-drawer
          side="right"
          v-model="drawerRight"
          bordered
          :width="200"
          :breakpoint="300"
          :mini="miniState"
          show-if-above
          @click.capture="miniState = false"
      >

        <template v-slot:mini>
          <q-scroll-area class="fit mini-slot cursor-pointer">
            <div class="q-py-lg">
              <div class="column items-center">
                <q-icon name="inbox" color="blue" class="mini-icon" />
              </div>
            </div>
          </q-scroll-area>
        </template>

        <q-btn
            dense
            round
            unelevated
            color="accent"
            icon="cancel"
            @click="miniState = true"
        />

        <q-scroll-area class="fit">
          <q-list padding>
            <q-item clickable v-ripple>
              <q-item-section>
                Inbox
              </q-item-section>
            </q-item>

            <q-item active clickable v-ripple>
              <q-item-section>
                Star
              </q-item-section>
            </q-item>

            <q-item clickable v-ripple>
              <q-item-section>
                Send
              </q-item-section>
            </q-item>

            <q-item clickable v-ripple>
              <q-item-section>
                Drafts
              </q-item-section>
            </q-item>
          </q-list>
        </q-scroll-area>
      </q-drawer>

      <q-page-container>
        <q-page class="q-pa-md">
          <p v-for="n in 15" :key="n">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit nihil praesentium molestias a adipisci, dolore vitae odit, quidem consequatur optio voluptates asperiores pariatur eos numquam rerum delectus commodi perferendis voluptate?
          </p>

          <q-page-sticky position="top-left" :offset="[18, 18]">
            <q-btn round color="primary" icon="arrow_back" class="rotate-45" />
          </q-page-sticky>
          <q-page-sticky position="top-right" :offset="[18, 18]">
            <q-btn round color="primary" icon="arrow_upward" class="rotate-45" />
          </q-page-sticky>
          <q-page-sticky position="bottom-left" :offset="[18, 18]">
            <q-btn round color="primary" icon="arrow_forward" class="rotate-135" />
          </q-page-sticky>
          <q-page-sticky position="bottom-right" :offset="[18, 18]">
            <q-btn round color="primary" icon="arrow_forward" class="rotate-45" />
          </q-page-sticky>
        </q-page>

        <q-page-scroller position="bottom">
          <q-btn fab icon="keyboard_arrow_up" color="red" />
        </q-page-scroller>
      </q-page-container>
    </q-layout>
  </q-page>
</template>

<script>

export default {
  name: "About",

  data() {
    return {
      drawerRight: true,
      miniState: false
    }
  }
}
</script>

<style scoped>

</style>