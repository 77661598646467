import Vue from 'vue'
import Vuex from 'vuex'
import server from './modules/server'
import layout from './modules/layout'
import units from './modules/units'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        server, layout, units
    },

    strict: debug,
})
